import { Box, BoxProps, SimpleGrid, SvgBox, SvgBoxProps } from "@govlaunch/web";
import Link from "next/link";
import React, { ReactNode } from "react";
import { useSelfie } from "~/components/auth/Auth";

export default function BottomNavbar() {
  const viewer = useSelfie();

  if (!viewer) {
    return null;
  }

  return (
    <Box
      position="fixed"
      zIndex={1200}
      width="100%"
      left={0}
      bottom={0}
      display={["block", "block", "none"]}
      height="60px"
      maxHeight="60px"
      bg="gray.800"
    >
      <SimpleGrid columns={5}>
        <Link href="/">
          <div>
            <NavbarItem
              svgProps={{
                width: "28px",
                height: "27px",
                viewBox: "0 0 28 27",
              }}
            >
              <path
                d="M14.3499 7.52507C15.3131 7.52507 16.0999 8.29786 16.0999 9.24215V17.437C16.0999 18.382 15.3131 19.1541 14.3499 19.1541C13.3874 19.1541 12.6 18.382 12.6 17.437V9.24215C12.6 8.29716 13.3874 7.52507 14.3499 7.52507ZM14 2.35863e-05C13.6985 -0.00154765 13.4018 0.0754091 13.139 0.223321L0.859023 7.13226C0.0400312 7.59355 -0.244166 8.62464 0.229029 9.42334C0.702925 10.2227 1.76061 10.4992 2.58101 10.0379L14 3.61269L25.419 10.0379C26.2394 10.4992 27.2971 10.2227 27.771 9.42404V9.42334C28.2442 8.62464 27.96 7.59355 27.141 7.13226L14.861 0.223321C14.5982 0.0755893 14.3015 -0.00135485 14 2.35863e-05ZM6.65036 11.6297C7.61285 11.6297 8.40034 12.3843 8.40034 13.3076V21.5801C8.40034 22.5034 7.61285 23.2587 6.65036 23.2587C5.68717 23.2587 4.90037 22.5034 4.90037 21.5801V13.3076C4.90037 12.3843 5.68717 11.6297 6.65036 11.6297ZM21.3498 11.6297C22.3123 11.6297 23.0998 12.3843 23.0998 13.3076V21.5801C23.0998 22.5034 22.3123 23.2587 21.3498 23.2587C20.3874 23.2587 19.5999 22.5034 19.5999 21.5808V13.3076C19.5999 12.3843 20.3874 11.6297 21.3498 11.6297ZM14.3499 20.5223C15.3124 20.5223 16.0999 21.3224 16.0999 22.3003V24.9022C16.0999 25.8801 15.3124 26.6795 14.3499 26.6795C13.3874 26.6795 12.6 25.8801 12.6 24.9022V22.3003C12.6 21.3224 13.3874 20.5223 14.3499 20.5223Z"
                fill="white"
              />
            </NavbarItem>
          </div>
        </Link>
        <Link href="/projects">
          <div>
            <NavbarItem
              svgProps={{
                width: "28px",
                height: "28px",
                viewBox: "0 0 24 24",
              }}
            >
              <path d="M16 10H13V7H11V10H8V12H11V15H13V12H16V10Z" fill="currentColor" />
              <path d="M15 22H9V24H15V22Z" fill="currentColor" />
              <path
                d="M14.6 21H9.40004L7.64004 17.69C6.51998 16.9706 5.59931 15.9806 4.9631 14.8113C4.32689 13.642 3.99567 12.3312 4.00004 11C4.00004 8.87827 4.8429 6.84344 6.34319 5.34315C7.84348 3.84285 9.87831 3 12 3C14.1218 3 16.1566 3.84285 17.6569 5.34315C19.1572 6.84344 20 8.87827 20 11C20.0044 12.3312 19.6732 13.642 19.037 14.8113C18.4008 15.9806 17.4801 16.9706 16.36 17.69L14.6 21ZM10.6 19H13.4L14.85 16.27L15.09 16.13C15.9758 15.5979 16.7091 14.846 17.2189 13.9472C17.7287 13.0485 17.9978 12.0333 18 11C18 9.4087 17.3679 7.88258 16.2427 6.75736C15.1175 5.63214 13.5913 5 12 5C10.4087 5 8.88262 5.63214 7.7574 6.75736C6.63218 7.88258 6.00004 9.4087 6.00004 11C6.00403 12.0316 6.27392 13.0447 6.78366 13.9416C7.2934 14.8384 8.02576 15.5887 8.91004 16.12L9.15004 16.26L10.6 19Z"
                fill="currentColor"
              />
            </NavbarItem>
          </div>
        </Link>
        <Link href="/podcast">
          <div>
            <NavbarItem
              svgProps={{
                width: "21px",
                height: "23px",
                viewBox: "0 0 21 23",
              }}
            >
              <path
                d="M10.0645 11.8529C11.4559 11.8529 12.5839 10.7905 12.5839 9.47987C12.5839 8.16928 11.4559 7.1068 10.0645 7.1068C8.67298 7.1068 7.54501 8.16928 7.54501 9.47987C7.54501 10.7905 8.67304 11.8529 10.0645 11.8529ZM10.0645 3.55016C6.58764 3.55016 3.76916 6.20496 3.76916 9.47981C3.76916 11.0724 4.43593 12.5183 5.5206 13.5835C5.83096 13.113 6.25952 12.6648 6.85043 12.3211C6.10986 11.5782 5.65543 10.5792 5.65543 9.47981C5.65543 7.18619 7.62942 5.32689 10.0645 5.32689C12.4996 5.32689 14.4735 7.18619 14.4735 9.47981C14.4735 10.5817 14.0171 11.5825 13.2737 12.3259C13.8636 12.6708 14.291 13.1201 14.6003 13.5913C15.6898 12.5252 16.3598 11.0763 16.3598 9.47981C16.3598 6.20503 13.5413 3.55016 10.0645 3.55016ZM10.0645 0C4.50598 0 0 4.24431 0 9.47987C0 12.8966 1.91937 15.8907 4.79764 17.5591C4.74035 17.1483 4.70365 16.7075 4.70365 16.2508C4.70365 16.2508 4.70088 15.8978 4.80165 15.388C3.01332 13.9732 1.87629 11.8518 1.87629 9.47987C1.87629 5.22032 5.54223 1.76734 10.0645 1.76734C14.5868 1.76734 18.2528 5.22032 18.2528 9.47987C18.2528 11.8568 17.1109 13.9822 15.316 15.397C15.4149 15.9019 15.4122 16.2507 15.4122 16.2507C15.4122 16.7105 15.3751 17.1543 15.3172 17.5673C18.2034 15.9007 20.129 12.9023 20.129 9.47987C20.129 4.24425 15.6229 0 10.0645 0ZM10.0645 13.0395C8.15126 13.0395 6.60019 14.5004 6.60019 16.3025C6.60019 18.1045 8.1512 24 10.0645 24C11.9777 24 13.5287 18.1046 13.5287 16.3025C13.5287 14.5004 11.9777 13.0395 10.0645 13.0395Z"
                fill="currentColor"
              />
            </NavbarItem>
          </div>
        </Link>
        <Link href="/products">
          <div>
            <NavbarItem
              svgProps={{
                width: "24px",
                height: "24px",
                viewBox: "0 0 24 24",
              }}
            >
              <path d="M20 10V20H4V10H20ZM22 8H2V22H22V8Z" fill="currentColor" />
              <path d="M20 5H4V7H20V5Z" fill="currentColor" />
              <path d="M18 2H6V4H18V2Z" fill="currentColor" />
            </NavbarItem>
          </div>
        </Link>
        <Link href={`/user?userSlug=${viewer.slug}`} as={`/@${viewer.slug}`}>
          <div>
            <NavbarItem
              svgProps={{
                width: "28px",
                height: "28px",
                viewBox: "0 0 28 28",
              }}
            >
              <path
                d="M10.7337 18.6027C8.45644 19.6564 6.78147 21.7781 6.34353 24.321L4.81055 24.057C5.33705 20.9999 7.34874 18.4549 10.0804 17.1909L10.7337 18.6027Z"
                fill="currentColor"
              />
              <path
                d="M19.5 20.1649C18.8575 19.5217 18.1004 18.9909 17.2646 18.6013L17.9218 17.1914C18.9215 17.6574 19.8287 18.293 20.6002 19.0653M20.6005 19.0656C21.9316 20.3968 22.8577 22.1213 23.1898 24.0571L21.6566 24.3201C21.3806 22.7109 20.6112 21.2761 19.5003 20.1652L19.5 20.1649"
                fill="currentColor"
              />
              <path
                d="M14 1.55556C7.12712 1.55556 1.55556 7.12712 1.55556 14C1.55556 20.8729 7.12712 26.4444 14 26.4444C20.8729 26.4444 26.4444 20.8729 26.4444 14C26.4444 7.12712 20.8729 1.55556 14 1.55556ZM0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z"
                fill="currentColor"
              />
              <path
                d="M13.9996 7.77772C11.5165 7.77772 9.3329 10.1145 9.3329 13.2222C9.3329 16.3299 11.5165 18.6666 13.9996 18.6666C16.4826 18.6666 18.6662 16.3299 18.6662 13.2222C18.6662 10.1145 16.4826 7.77772 13.9996 7.77772ZM7.77734 13.2222C7.77734 9.45698 10.4688 6.22217 13.9996 6.22217C17.5303 6.22217 20.2218 9.45698 20.2218 13.2222C20.2218 16.9874 17.5303 20.2222 13.9996 20.2222C10.4688 20.2222 7.77734 16.9874 7.77734 13.2222Z"
                fill="currentColor"
              />
            </NavbarItem>
          </div>
        </Link>
      </SimpleGrid>
    </Box>
  );
}

interface INavbarItemProps extends BoxProps {
  children: ReactNode;
  svgProps: SvgBoxProps;
}

function NavbarItem({ children, svgProps, ...props }: INavbarItemProps) {
  return (
    <Box as="a" height="60px" display="flex" alignItems="center" justifyContent="center" {...props}>
      <SvgBox
        {...svgProps}
        color="gray.100"
        _hover={{
          color: "white",
        }}
      >
        {children}
      </SvgBox>
    </Box>
  );
}
