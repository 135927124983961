import React from "react";
import * as palette from "@govlaunch/palette";

interface IScreenReaderOnlyLabelProps {
  id: string;
  text: string;
  color?: string;
}

export default function ScreenReaderOnlyLabel({ id, text, color }: IScreenReaderOnlyLabelProps) {
  return (
    <label
      id={id}
      css={{
        position: "absolute",
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0,0,0,0)",
        border: 0,
        color: color || palette.white,
      }}
    >
      {text}
    </label>
  );
}
