import React, { ReactNode } from "react";
import NotificationIcon from "~/src/components/notifications/NotificationIcon";

interface INotificationBellProps {
  onClick: () => void;
  innerRef?: React.Ref<HTMLDivElement>;
  children: ReactNode;
}

export default function NotificationBell({ onClick, children, innerRef, ...rest }: INotificationBellProps) {
  return (
    <div
      css={{
        display: "inline-flex",
        position: "relative",
        cursor: "pointer",
      }}
      role="button"
      onClick={onClick}
      aria-label="Notifications"
      ref={innerRef}
      {...rest}
    >
      <NotificationIcon />

      {children}
    </div>
  );
}
