import styled from "@emotion/styled";
import mediaQueries from "~/lib/utils/mediaQueries";

interface IResultItemProps {
  highlighted: boolean;
}

export default styled.a<IResultItemProps>(
  mediaQueries({
    display: "flex",
    alignItems: "center",
    padding: [12, "12px 25px"],
    ":hover": {
      backgroundColor: "#F2F4F8",
    },
  }),
  ({ highlighted }: any) => {
    if (highlighted) {
      return {
        backgroundColor: "#F2F4F8",
      };
    }
  },
);
