import { useIntl } from "react-intl";

export default function useIntlDate() {
  const intl = useIntl();

  return {
    format: (value: Date | number) =>
      intl.formatDate(value, {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }),
  };
}
