import React from "react";

export default function ModalCloseIcon(props: any) {
  return (
    <svg width={9} height={9} {...props}>
      <path
        fill="#A5ADBA"
        fillRule="evenodd"
        d="M4.5 2.885L7.385 0 9 1.615 6.115 4.5 9 7.385 7.385 9 4.5 6.115 1.615 9 0 7.385 2.885 4.5 0 1.615 1.615 0 4.5 2.885z"
      />
    </svg>
  );
}
