import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import useIntlDate from "~/lib/hooks/useIntlDate";
import Strongify from "~/lib/utils/strongify";
import Alert from "~/components/makers/Alert";

interface IScheduledToCancelStickyNoticeBannerProps {
  vendorSlug: string;
  willCancelAt: Date;
}

export default function ScheduledToCancelStickyNoticeBanner({
  vendorSlug,
  willCancelAt,
}: IScheduledToCancelStickyNoticeBannerProps) {
  const intlDate = useIntlDate();

  const contactSupport = (
    <a
      href="mailto:hello@govlaunch.com"
      css={{
        color: palette.innovatorBlue,
      }}
    >
      <FormattedMessage defaultMessage="contact support" id="MCkewI" />
    </a>
  );

  const linkToAccountSettings = (
    <Link href="/vendors/[vendorSlug]/[[...content]]" as={`/vendors/${vendorSlug}/account`} passHref={true}>
      <a
        href="mailto:hello@govlaunch.com"
        css={{
          color: palette.innovatorBlue,
        }}
      >
        <FormattedMessage defaultMessage="account settings" id="f5VIwq" />
      </a>
    </Link>
  );

  return (
    <Alert>
      <p>
        <FormattedMessage
          defaultMessage="Your subscription will be cancelled on <strong>{date}</strong> and all the
        new content you add as of today will be erased by this date. You can go to {linkToAccountSettings} to reactivate
        your subscription. If you have any questions you can always {contactSupport}." id="Lj/gRU"
          values={{
            date: intlDate.format(willCancelAt),
            linkToAccountSettings: linkToAccountSettings,
            contactSupport: contactSupport,
            strong: (chunk: any) => Strongify(chunk),
          }}
        />
      </p>
    </Alert>
  );
}
