import { Mutation } from "@apollo/client/react/components";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import DismissGovernmentProfileBanner from "~/lib/mutations/DismissGovernmentProfileBanner";
import {
  IDismissGovernmentProfileBannerMutation,
  IDismissGovernmentProfileBannerMutationVariables,
} from "~/lib/mutations/__generated__/DismissGovernmentProfileBanner.generated";
import SelfieQuery from "~/lib/queries/SelfieQuery";
import { useSelfie } from "~/components/auth/Auth";

function CompleteGovernmentProfileBanner() {
  const viewer = useSelfie();

  if (!viewer || !(viewer.__typename === "GovernmentUser" && viewer.showCompleteGovernmentProfileBanner)) {
    return null;
  }

  return (
    <Mutation<IDismissGovernmentProfileBannerMutationVariables, IDismissGovernmentProfileBannerMutation>
      mutation={DismissGovernmentProfileBanner}
      update={(proxy) => {
        proxy.writeQuery({
          query: SelfieQuery,
          data: {
            __typename: "Query",
            selfie: {
              ...viewer,
              showCompleteGovernmentProfileBanner: false,
            },
          },
        });
      }}
    >
      {(dismissActionCenter) => (
        <div
          css={{
            position: "relative",
            padding: "24px 32px",
            border: `solid 1px #6f5b00`,
            backgroundColor: "rgba(253, 235, 148, 0.5)",
            color: "#6f5b00",
            borderRadius: 5,
            width: "100%",
          }}
        >
          <FormattedMessage defaultMessage="Help us! We're missing some key information about your government." id="gUl6YV" />{" "}
          <Link
            href="/governments/[governmentSlug]/[[...content]]"
            as={`/governments/${viewer.government.slug}/settings`}
            passHref={true}
          >
            <a
              css={{
                color: "#6f5b00",
                fontWeight: 600,
                ":hover": {
                  textDecoration: "underline !important",
                },
              }}
            >
              <FormattedMessage defaultMessage="Add it now" id="Nq9h7m" /> →
            </a>
          </Link>
          <span
            role="button"
            onClick={() => dismissActionCenter()}
            css={{
              position: "absolute",
              top: 12,
              right: 12,
              textTransform: "uppercase",
              fontSize: 12,
              letterSpacing: 0.5,
              color: "#6f5b00",
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
          </span>
        </div>
      )}
    </Mutation>
  );
}

export default CompleteGovernmentProfileBanner;
