import React from "react";

interface INotificationCountProps {
  children: string | number;
}

export default function NotificationCount({ children }: INotificationCountProps) {
  return (
    <span
      css={{
        backgroundColor: "#28D094",
        color: "#FAFAF9",
        fontSize: 12,
        width: 16,
        height: 16,
        lineHeight: 20,
        borderRadius: 20,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        right: -6,
      }}
    >
      {children}
    </span>
  );
}
