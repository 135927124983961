import PubNub from "pubnub";

function getPubNubClient() {
  const environment = process.env.GOVLAUNCH_ENV || process.env.NODE_ENV || "development";

  if (environment === "test") {
    return new PubNub({
      subscribeKey: "",
      ssl: true,
      uuid: "66e46024-44b1-4733-965c-1cca9b3d8ea3",
    });
  }

  return new PubNub({
    subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY || "",
    ssl: true,
    uuid: process.env.PUBNUB_UUID || "",
  });
}

export default getPubNubClient();
