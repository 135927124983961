import { LoadingIndicator } from "@govlaunch/core";
import {
  Notifications,
  NotificationSection,
  NotificationsOverflow,
  NotificationsWrapper,
} from "~/src/components/notifications";
import * as palette from "@govlaunch/palette";
import last from "lodash/fp/last";
import React, { Component, Fragment, ReactElement } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Notification from "~/components/notifications/Notification";
import { FormattedMessage } from "react-intl";

interface IDesktopNotificationsProps {
  token: string | null;
  channels: string[];
}

export default class DesktopNotifications extends Component<IDesktopNotificationsProps> {
  private overflow: HTMLDivElement | null = null;

  render() {
    const { token, channels } = this.props;

    if (!token) {
      return null;
    }

    return (
      <Notifications token={token} channels={channels}>
        {({ notifications, loading, groups, unreadCount, close, loadMore, hasMore, read, readAll }: any) => (
          <>
            <NotificationSection>
              <div
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <FormattedMessage defaultMessage="Notifications" id="NAidKb" />
                </div>

                {unreadCount > 0 && (
                  <a
                    href="#"
                    css={{
                      color: palette.darkGray,
                      textDecoration: "underline",
                    }}
                    onClick={event => {
                      event.preventDefault();
                      readAll();
                    }}
                  >
                    <FormattedMessage defaultMessage="Mark all as read" id="/af+jQ" />
                  </a>
                )}
              </div>
            </NotificationSection>

            {notifications.length === 0 && (
              <div
                css={{
                  padding: 15,
                  display: "flex",
                  justifyContent: "center",
                  background: "#fff",
                  borderBottomRightRadius: 4,
                  borderBottomLeftRadius: 4,
                }}
              >
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <p css={{ margin: 0, fontSize: 14, color: palette.darkGray }}>
                    <FormattedMessage defaultMessage="No new notifications" id="layEo+" />
                  </p>
                )}
              </div>
            )}

            <NotificationsOverflow
              innerRef={(node: HTMLDivElement) => {
                this.overflow = node;
              }}
            >
              <NotificationsWrapper>
                {notifications.map(
                  (notification: any, index: number): ReactElement => (
                    <Fragment key={notification._id}>
                      <Notification
                        notification={notification}
                        group={groups[notification.group]}
                        onRead={({ notificationUrl }) => {
                          read(notification).then(() => {
                            close();
                            window.location.href = notificationUrl;
                          });
                        }}
                      />

                      {index === notifications.length - 1 && (
                        <VisibilitySensor
                          delayedCall={true}
                          intervalDelay={1200}
                          partialVisibility={false}
                          containment={this.overflow || undefined}
                          scrollCheck={true}
                          onChange={(isVisible: boolean) => {
                            if (isVisible && hasMore && !loading) {
                              const lastNotification = last(notifications);

                              if (lastNotification) {
                                loadMore((lastNotification as any)._id);
                              }
                            }
                          }}
                        />
                      )}

                      {shouldDisplaySpinner(index, notifications.length) && hasMore && (
                        <div css={{ backgroundColor: palette.white, display: "flex", height: 50 }}>
                          <LoadingIndicator margin="auto" />
                        </div>
                      )}
                    </Fragment>
                  ),
                )}
              </NotificationsWrapper>
            </NotificationsOverflow>
          </>
        )}
      </Notifications>
    );
  }
}

function shouldDisplaySpinner(index: number, length: number, size = 10): boolean {
  const currentPage = Math.ceil(length / size);
  const lastItemInCurrentPage = currentPage * 10 - 1;
  return index === lastItemInCurrentPage;
}
