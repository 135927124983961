import React from "react";

interface IHorizontalLine {
  color: string;
}

const HorizontalLine: React.FunctionComponent<IHorizontalLine> = ({ color }) => {
  return (
    <div
      css={{
        height: 1,
        width: "100%",
        backgroundColor: color,
      }}
    />
  );
};

export default HorizontalLine;
