import fullName from "~/src/components/notifications/fullName";
import get from "lodash/fp/get";
import { useIntl, IntlShape } from "react-intl";

interface IProducerNamesProps {
  notification: any;
  limitOfNames?: number;
  pathToProducer?: string;
  pathToNameProducer?: string;
  group: {
    notifications: Record<string, any>[];
    totalCount: number;
  } | null;
}

export default function ProducerNames({
  notification,
  group = null,
  limitOfNames = 3,
  pathToProducer,
  pathToNameProducer,
}: IProducerNamesProps) {
  const intl = useIntl();

  if (!group) {
    const producer = pathToNameProducer
      ? get(pathToNameProducer, notification)
      : get(pathToProducer || "", notification);

    if (!producer) {
      return null;
    } else {
      if (pathToNameProducer) {
        return producer;
      }

      return fullName(producer);
    }
  }

  const names = group.notifications.slice(0, limitOfNames).map(notification => {
    if (pathToNameProducer) {
      return get(pathToNameProducer, notification);
    }

    return fullName(get(pathToProducer || "", notification));
  });

  return naturalStringJoin(intl, names, group.totalCount);
}

function naturalStringJoin(intl: IntlShape, strings: string[], totalCount: number) {
  const filteredStrings = strings.map(string => string.trim()).filter(Boolean);

  if (filteredStrings.length === 0) {
    return "";
  }

  if (filteredStrings.length === 1) {
    return filteredStrings[0];
  }

  if (filteredStrings.length === 2) {
    const [first, second] = filteredStrings;

    return intl.formatMessage(
      { defaultMessage: '{first} and {second}', id: 'zK/CbE' },
      {
        first,
        second,
      },
    );
  }

  if (filteredStrings.length === 3 && totalCount === 3) {
    const [first, second, last] = filteredStrings;

    return intl.formatMessage(
      { defaultMessage: '{first}, {second} and {last}', id: 'tu4vwG' },
      {
        first,
        second,
        last,
      },
    );
  }

  return intl.formatMessage(
    { defaultMessage: '{names} and {totalCount} others', id: 'gtcWky' },
    {
      names: filteredStrings.join(", "),
      totalCount: totalCount - 1,
    },
  );
}
