import { mediumGray } from "@govlaunch/palette";
import moment from "moment";
import React, { Component } from "react";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";

interface INotificationTimeAgoProps {
  time: string | number;
}

export default class NotificationTimeAgo extends Component<INotificationTimeAgoProps> {
  componentDidMount() {
    this.interval = setInterval(() => this.forceUpdate(), 60 * 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  interval?: NodeJS.Timeout = undefined;

  render() {
    const { time } = this.props;
    const datetime = moment(time).toISOString();

    return (
      <time
        css={{
          margin: 0,
          fontSize: 12,
          lineHeight: "15px",
          color: mediumGray,
        }}
        dateTime={datetime}
      >
        <IntlRelativeTime value={time} />
      </time>
    );
  }
}
