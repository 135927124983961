import React, { ReactElement } from "react";
import { className } from "~/src/components/notifications/NotificationBox";

interface INotificationsWrapperProps {
  children: ReactElement;
}

export default function NotificationsWrapper({ children }: INotificationsWrapperProps) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 425,
        [`& .${className}:first-of-type`]: {
          borderTop: 0,
        },
        background: "#fff",
      }}
    >
      {children}
    </div>
  );
}
