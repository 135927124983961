import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, BoxProps, AnchorButton } from "@govlaunch/web";
import Link from "next/link";

interface IJoinButtonsProps extends BoxProps {
  isMobile?: boolean;
}

export default function JoinButtons({ isMobile = false, ...props }: IJoinButtonsProps) {
  return (
    <Box {...props}>
      {!isMobile && (
        <Link href="/signup" passHref={true}>
          <AnchorButton variant="primary" rounded="xl" px={3} height={6} lineHeight="tall" mr={2}>
            <FormattedMessage defaultMessage="Join" id="P6PLpi" />
          </AnchorButton>
        </Link>
      )}
      <Link href="/signin" passHref={true}>
        <AnchorButton variant="primary" rounded="xl" px={3} height={6} lineHeight="tall">
          <FormattedMessage defaultMessage="Sign In" id="Ub+AGc" />
        </AnchorButton>
      </Link>
    </Box>
  );
}
