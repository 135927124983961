import { Mobile } from "@govlaunch/core";
import React, { ReactElement } from "react";

import { Toggle } from "react-powerplug";
import { MobileScreen, MobileScreenHeader } from "~/components/mobile";
import { Modal, ModalContent, ModalCloseButton, ModalOverlay } from "@govlaunch/web";

interface ITermsProps {
  title: string;
  policyUrl: string;
  children?(toggleOpen: () => void): ReactElement | null;
  isOpen?: boolean;
  onClose?: () => void;
}

export default function TermsIframeModal({ policyUrl, isOpen, onClose, title, children }: ITermsProps) {
  return (
    <Toggle initial={false}>
      {({ on, toggle }: { on: boolean; toggle: () => void }) => (
        <>
          <Mobile>
            {(isMobile: boolean) =>
              isMobile ? (
                <MobileScreen isOpen={isOpen || on}>
                  {() => (
                    <>
                      <MobileScreenHeader onRequestClose={onClose || toggle}>{title}</MobileScreenHeader>

                      <iframe
                        src={policyUrl}
                        frameBorder={0}
                        width="100%"
                        height="91%"
                        style={{
                          overflow: "auto",
                        }}
                      />
                    </>
                  )}
                </MobileScreen>
              ) : (
                <Modal isOpen={isOpen || on} onClose={onClose || toggle} closeOnEsc={true}>
                  <ModalOverlay />
                  <ModalContent minWidth={800}>
                    <ModalCloseButton onClick={onClose || toggle} />

                    <iframe title={title} src={policyUrl} frameBorder={0} width={800} height={800} />
                  </ModalContent>
                </Modal>
              )
            }
          </Mobile>

          {children && children(toggle)}
        </>
      )}
    </Toggle>
  );
}
