import { SvgBox, SvgBoxProps } from "@govlaunch/web";
import React from "react";

export default function DropdownArrowUp(props: SvgBoxProps) {
  return (
    <SvgBox width="14" height="6" viewBox="0 0 14 6" fill="none" {...props}>
      <path d="M7 -6.99382e-07L0.0717959 6L13.9282 6L7 -6.99382e-07Z" fill={props.fill || "#086BE5"} />
    </SvgBox>
  );
}
