import { useRouter } from "next/router";
import mediaQueries from "~/components/mediaQueries";
import { useIntlLocale } from "~/lib/withIntl";
import LanguageSelectDropDown from "~/src/components/intl/LanguageSelectDropDown";

const LanguageSelect = () => {
  const router = useRouter();
  const { locale, locales, changeLocale } = useIntlLocale();

  const currentLocale = locales.find((l) => l.id === router.locale) ||
    locales.find((l) => l.id === locale) || {
      id: "en-US",
      apiLanguage: "en-US",
      name: "English",
      countryCode: "US",
      disabled: false,
    };

  return (
    <div
      css={mediaQueries({
        width: ["100%", "100%", "250px", "250px"],
      })}
    >
      <LanguageSelectDropDown
        locales={locales}
        initialSelectedItem={currentLocale}
        onChange={(selectedItem) => {
          if (selectedItem) {
            changeLocale(selectedItem.id);
          }
        }}
      />
    </div>
  );
};

export default LanguageSelect;
