import React from "react";
import useIsMobile from "~/lib/hooks/useIsMobile";
import Searchbar from "~/components/searchbar/Searchbar";
import MagnifyingClass from "~/components/icons/MagnifyingClass";
import { useToggle } from "react-use";

const NavbarSearch: React.FunctionComponent = () => {
  const isMobile = useIsMobile();
  const [isSearching, toggleSearching] = useToggle(false);

  if (isMobile && isSearching) {
    return <Searchbar initialIsOpen={true} onClose={() => toggleSearching(false)} />;
  }

  if (isMobile) {
    return (
      <div onClick={() => toggleSearching()}>
        <MagnifyingClass width={20} height={20} fill="#ADB6C3" />
      </div>
    );
  }

  return <Searchbar />;
};

export default NavbarSearch;
