import gql from "graphql-tag";

export default gql`
  query AutoCompleteUsers($searchQuery: String!) {
    autoCompleteUsers(
      searchQuery: $searchQuery
      scopes: [PENDING, GOVERNMENT, VENDOR, COMMUNITY, CITIZEN, INNOVATOR, DISRUPTOR]
    ) {
      __typename
      ... on PendingUser {
        _id
        fullName
        email
        avatar
      }
      ... on VendorUser {
        _id
        fullName
        email
        avatar
      }
      ... on GovernmentUser {
        _id
        fullName
        email
        avatar
      }
    }
  }
`;
