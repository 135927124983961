import React from "react";
import * as palette from "@govlaunch/palette";
import HighlightWords from "react-highlight-words";
import getTagFromQuery from "~/components/searchbar/results/getTagFromQuery";
import { ITag } from "~/types/types";
import escapeRegExp from "lodash/fp/escapeRegExp";

interface IHighlightTags {
  tags: Pick<ITag, "name">[];
  query: string | null;
}

export default function HighlightTags({ tags, query }: IHighlightTags) {
  return (
    <HighlightWords
      highlightStyle={{
        background: palette.lightestGray,
        color: palette.text,
        fontWeight: "bold",
      }}
      searchWords={[escapeRegExp(query || "")]}
      textToHighlight={getTagFromQuery(tags, query) || ""}
    />
  );
}
