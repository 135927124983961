import { Modal } from "@govlaunch/core";
import React, { ReactElement } from "react";
import { Toggle } from "react-powerplug";
import useIsMobile from "~/lib/hooks/useIsMobile";
import ModalCloseIcon from "~/components/icons/ModalCloseIcon";
import { MobileScreen, MobileScreenHeader } from "~/components/mobile";

interface ITermsProps {
  title: string;
  policyUrl: string;
  children(toggleOpen: () => void): ReactElement | null;
}

export default function Terms({ policyUrl, title, children }: ITermsProps) {
  const isMobile = useIsMobile();

  return (
    <Toggle initial={false}>
      {({ on, toggle }) => (
        <>
          {isMobile ? (
            <MobileScreen isOpen={on}>
              {() => (
                <>
                  <MobileScreenHeader onRequestClose={toggle}>{title}</MobileScreenHeader>

                  <iframe
                    src={policyUrl}
                    frameBorder={0}
                    width="100%"
                    height="91%"
                    style={{
                      overflow: "auto",
                    }}
                  />
                </>
              )}
            </MobileScreen>
          ) : (
            <Modal
              isOpen={on}
              onRequestClose={toggle}
              ariaHideApp={false}
              overlayStyle={{
                overflow: "hidden",
              }}
            >
              <span
                css={{
                  position: "absolute",
                  top: 0,
                  right: 16,
                  padding: 20,
                  cursor: "pointer",
                }}
                role="button"
                aria-label="Close"
                onClick={toggle}
              >
                <ModalCloseIcon />
              </span>

              <iframe title={title} src={policyUrl} frameBorder={0} width={800} height={800} />
            </Modal>
          )}

          {children(toggle)}
        </>
      )}
    </Toggle>
  );
}
