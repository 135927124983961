import React from "react";
import Head from "next/head";

export default function HreflangTags() {
  return (
    <Head>
      <link key="hreflangPT" rel="alternate" hrefLang="pt" href="https://govlaunch.com/pt-BR/" />
      <link key="hreflangBg" rel="alternate" hrefLang="bg" href="https://govlaunch.com/bg/" />
      <link key="hreflangDe" rel="alternate" hrefLang="de" href="https://govlaunch.com/de/" />
      <link key="hreflangEs" rel="alternate" hrefLang="es" href="https://govlaunch.com/es/" />
      <link key="hreflangFr" rel="alternate" hrefLang="fr" href="https://govlaunch.com/fr/" />
      <link key="hreflangIt" rel="alternate" hrefLang="it" href="https://govlaunch.com/it/" />
      <link key="hreflangNl" rel="alternate" hrefLang="nl" href="https://govlaunch.com/nl/" />
      <link key="hreflangSv" rel="alternate" hrefLang="sv" href="https://govlaunch.com/sv/" />
      <link key="hreflangDefault" rel="alternate" hrefLang="x-default" href="https://govlaunch.com/" />
    </Head>
  );
}
