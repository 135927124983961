type GovlaunchApp =
  | "portal"
  | "site"
  | "main"
  | "govlaunch_frontend"
  | "frontend"
  | "portal"
  | "disruptors"
  | "disruptors-frontend"
  | "innovators"
  | "innovators-frontend";

export default function getGovlaunchBaseUrl(app: GovlaunchApp = "portal"): string {
  const environment = process.env.GOVLAUNCH_ENV || process.env.NODE_ENV;

  if (["site", "main", "govlaunch_frontend", "frontend", "portal"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3000`;
    } else if (environment === "production") {
      return `https://govlaunch.com`;
    } else if (environment === "qa") {
      return `https://qa.govlaunch.com`;
    }
  }

  if (["disruptors-frontend", "disruptors"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3001`;
    } else if (environment === "production") {
      return `https://disruptors.govlaunch.com`;
    } else if (environment === "qa") {
      return `https://disruptors.qa.govlaunch.com`;
    }
  }

  if (["innovators-frontend", "innovators"].includes(app)) {
    if (environment === "development") {
      return `http://localhost:3002`;
    } else if (environment === "production") {
      return `https://innovators.govlaunch.com`;
    } else if (environment === "qa") {
      return `https://innovators.qa.govlaunch.com`;
    }
  }

  return "";
}
