import React, { InputHTMLAttributes, createRef } from "react";
import MagnifyingClass from "~/components/icons/MagnifyingClass";
import { useIntl } from "react-intl";

interface ISearchInputProps {
  isFocused: boolean;
  onClose: (args: any) => void;
  onKeyDown: (args: any) => void;
}

export default function SearchInput({
  isFocused,
  onKeyDown,
  onClose,
  ...props
}: ISearchInputProps & InputHTMLAttributes<HTMLInputElement>) {
  const inputRef = createRef<HTMLInputElement>();
  const intl = useIntl();

  return (
    <div
      css={{
        width: "100%",
        position: "relative",
      }}
    >
      <input
        {...props}
        ref={inputRef}
        onKeyDown={e => {
          onKeyDown(e);

          if (e.key === "Escape") {
            (e.target as any).blur();
          }
        }}
        placeholder={intl.formatMessage(
          {
            defaultMessage: 'Search the {wiki}...', id: 'DRxm+E',
          },
          { wiki: "wiki" },
        )}
        css={{
          padding: "0 16px",
          height: 40,
          width: "100%",
          background: isFocused ? "#e3e6eb" : "#3F4A5A",
          borderWidth: `1px`,
          borderStyle: "solid",
          borderColor: isFocused ? "#2F3844" : "#5A6677",
          borderRadius: 4,
          borderBottomRightRadius: isFocused ? 0 : 4,
          borderBottomLeftRadius: isFocused ? 0 : 4,
          color: isFocused ? undefined : "#FAFAF9",
          outline: 0,
          fontSize: 16,
          fontWeight: 500,
          "::placeholder": {
            color: "#ADB6C3",
          },
        }}
      />

      {!isFocused && (
        <div
          css={{
            position: "absolute",
            cursor: "pointer",
            right: 16,
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <MagnifyingClass width={20} height={20} fill="#ADB6C3" onClick={() => inputRef.current?.focus()} />
        </div>
      )}

      {isFocused && (
        <div
          css={{
            cursor: "pointer",
            position: "absolute",
            top: "50%",
            right: 10,
            transform: "translateY(-50%)",
            padding: 6,
          }}
          onClick={onClose}
          role="button"
          aria-label={intl.formatMessage({
            defaultMessage: 'close searchbar', id: 'hOnqO9',
          })}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M10.9552 7.99997L15.3882 3.56712C16.2039 2.75098 16.2039 1.42819 15.3882 0.612481C14.5722 -0.20416 13.2489 -0.20416 12.4327 0.612481L8.00004 5.04476L3.56734 0.612481C2.75113 -0.20416 1.42785 -0.20416 0.611783 0.612481C-0.203928 1.42819 -0.203928 2.75098 0.611783 3.56712L5.04491 7.99997L0.611783 12.4328C-0.203928 13.2483 -0.203928 14.5717 0.611783 15.3879C1.42785 16.204 2.75113 16.204 3.56734 15.3879L8.00004 10.955L12.4327 15.3879C13.2489 16.204 14.5722 16.204 15.3882 15.3879C16.2039 14.5717 16.2039 13.2483 15.3882 12.4328L10.9552 7.99997Z"
              fill="#1CB7EB"
            />
          </svg>
        </div>
      )}
    </div>
  );
}
