import {
  Anchor,
  AnchorProps,
  Box,
  Stack,
  Popover,
  Button,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
} from "@govlaunch/web";
import React from "react";
import { useToggle } from "react-use";
import TermsIframeModal from "~/src/shared/components/TermsIframeModal";
import Link from "next/link";
import { FormattedMessage, useIntl } from "react-intl";
import useIntlPolicies from "~/src/shared/hooks/useIntlPolicies";

export default function NavbarPopoverMenu() {
  const [isTermsOpen, toggleTerms] = useToggle(false);
  const [isPrivacyPolicyOpen, togglePrivacyPolicy] = useToggle(false);
  const [isCookiePolicyOpen, toggleCookiePolicy] = useToggle(false);
  const policiesUrls = useIntlPolicies();
  const intl = useIntl();

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button
            type="button"
            color="gray.100"
            width="12px"
            minWidth="12px"
            height="18px"
            padding={0}
            margin={0}
            aria-label="dropdown menu"
          >
            <svg viewBox="0 0 384 384" width="1em" height="1em">
              <circle fill="currentColor" cx={42.667} cy={192} r={42.667} />
              <circle fill="currentColor" cx={192} cy={192} r={42.667} />
              <circle fill="currentColor" cx={341.333} cy={192} r={42.667} />
            </svg>
          </Button>
        </PopoverTrigger>

        <PopoverContent maxWidth="165px" arrowShadowColor="#ADB6C3" bg="gray.900">
          <PopoverArrow />

          <Box width="165px" p={4} borderRadius="md" border="1px solid" overflow="hidden" minWidth={0}>
            <Stack direction="column" spacing={4}>
              <Box>
                <Link href="/about" passHref={true}>
                  <Anchor fontSize="md" color="white" rel="noreferrer noopener" title="About">
                    <FormattedMessage defaultMessage="About" id="g5pX+a" />
                  </Anchor>
                </Link>
              </Box>

              <MenuAnchor external="govlaunch" href="https://advertise.govlaunch.com/" title="Help">
                <FormattedMessage defaultMessage="Advertise" id="2OzlCZ" />
              </MenuAnchor>

              <MenuAnchor external="govlaunch" href="https://help.govlaunch.com/en/" title="Help">
                <FormattedMessage defaultMessage="Help" id="SENRqu" />
              </MenuAnchor>

              <Box>
                <Link href="/podcast" passHref={true}>
                  <Anchor fontSize="md" color="white" rel="noreferrer noopener" title="Podcast">
                    <FormattedMessage id="uNMm97" defaultMessage="Podcast" />
                  </Anchor>
                </Link>
              </Box>

              <MenuAnchor external="govlaunch" href="https://blog.govlaunch.com" title="Blog">
                <FormattedMessage defaultMessage="Blog" id="tv5FG3" />
              </MenuAnchor>
            </Stack>

            <Box height="1px" width="100%" bg="gray.300" my={4} />

            <div
              css={{
                position: "relative",
                display: "grid",
                rowGap: 16,
              }}
            >
              <MenuAnchor
                onClick={toggleTerms}
                rel="noreferrer noopener"
                href="#"
                title={intl.formatMessage({ defaultMessage: "Terms of Service", id: "32rBNK" })}
                css={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "inline",
                }}
              >
                <FormattedMessage defaultMessage="Terms of Service" id="32rBNK" />
              </MenuAnchor>
              <MenuAnchor
                onClick={togglePrivacyPolicy}
                rel="noreferrer noopener"
                href="#"
                title={intl.formatMessage({ defaultMessage: "Privacy Policy", id: "vx0nkZ" })}
                css={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "inline",
                }}
              >
                <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
              </MenuAnchor>
              <MenuAnchor
                onClick={toggleCookiePolicy}
                rel="noreferrer noopener"
                href="#"
                title={intl.formatMessage({ defaultMessage: "Cookie Policy", id: "cA0kQY" })}
                css={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "inline",
                }}
              >
                <FormattedMessage defaultMessage="Cookie Policy" id="cA0kQY" />
              </MenuAnchor>
            </div>
          </Box>
        </PopoverContent>
      </Popover>

      <TermsIframeModal
        title="Terms and Conditions"
        policyUrl={policiesUrls.termsAndConditions}
        isOpen={isTermsOpen}
        onClose={toggleTerms}
      />

      <TermsIframeModal
        title="Privacy Policy"
        policyUrl={policiesUrls.privacyPolicy}
        isOpen={isPrivacyPolicyOpen}
        onClose={togglePrivacyPolicy}
      />

      <TermsIframeModal
        title="Cookie Policy"
        policyUrl={policiesUrls.cookiePolicy}
        isOpen={isCookiePolicyOpen}
        onClose={toggleCookiePolicy}
      />
    </>
  );
}

function MenuAnchor(props: AnchorProps) {
  return <Anchor fontSize="md" color="white" {...props} />;
}
