import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import Strongify from "~/lib/utils/strongify";
import Alert from "~/components/makers/Alert";

export default function OverdueStickyNoticeBanner() {
  const pleaseUpdatePaymentMethod = (
    <Link href="/billing/payment" passHref={true}>
      <a
        css={{
          color: palette.innovatorBlue,
        }}
      >
        <FormattedMessage defaultMessage="Please update your payment method" id="ABVuHe" />
      </a>
    </Link>
  );

  const contactSupport = (
    <a
      href="mailto:hello@govlaunch.com"
      css={{
        color: palette.innovatorBlue,
      }}
    >
      <FormattedMessage defaultMessage="contact support" id="MCkewI" />
    </a>
  );

  return (
    <Alert>
      <p>
        <FormattedMessage
          defaultMessage="<strong>We are having a problem billing your account.</strong> {pleaseUpdatePaymentMethod} or call your payment
        provider for details on why the transaction failed. You can {contactSupport} with any questions." id="BneSKz"
          values={{
            pleaseUpdatePaymentMethod: pleaseUpdatePaymentMethod,
            contactSupport: contactSupport,
            strong: (chunk: any) => Strongify(chunk),
          }}
        />
      </p>
    </Alert>
  );
}
