import React, { ReactNode, forwardRef } from "react";
import { Box, Anchor, Flex, Stack, AnchorProps } from "@govlaunch/web";
import Link from "next/link";
import NavbarPopoverMenu from "~/src/components/navbar/NavbarPopoverMenu";
import NavbarSearch from "~/components/navbar/NavbarSearch";
import { useSelfie } from "~/components/auth/Auth";
import UserNotifications from "~/components/notifications/UserNotifications";
import UserProfileDropdown from "~/src/components/navbar/UserProfileDropdown";
import { DesktopNavbarLogo } from "~/src/components/navbar/icons";
import JoinButtons from "~/src/components/navbar/JoinButtons";
import { FormattedMessage } from "react-intl";

export default function Navbar() {
  const user = useSelfie();

  return (
    <Box bg="gray.700" as="header">
      <Box
        maxWidth="1224px"
        display="flex"
        mx="auto"
        alignItems="center"
        position="relative"
        width="100%"
        height="81px"
      >
        <header>
          <Link href="/" passHref={true}>
            <Anchor display="inline-flex" aria-label="homepage">
              <DesktopNavbarLogo />
            </Anchor>
          </Link>
        </header>
        <Box flex={1} ml={8} mr={6}>
          <NavbarSearch />
        </Box>
        <nav>
          <Stack as="ul" listStyleType="none" direction="row" display="flex" spacing={6} ml="auto" mr={user ? 8 : 0}>
            <Box as="li">
              <Link href="/collections" passHref={true}>
                <NavbarAnchor>
                  <FormattedMessage defaultMessage="Collections" id="ulh3kf" />
                </NavbarAnchor>
              </Link>
            </Box>
            <Box as="li">
              <Link href="/projects" passHref={true}>
                <NavbarAnchor>
                  <FormattedMessage defaultMessage="Projects" id="UxTJRa" />
                </NavbarAnchor>
              </Link>
            </Box>
            <Box as="li">
              <Link href="/products" passHref={true}>
                <NavbarAnchor>
                  <FormattedMessage id="7NFfmz" defaultMessage="Products" />
                </NavbarAnchor>
              </Link>
            </Box>
            <Box as="li">
              <Link href="/stories" as="/stories" passHref={true}>
                <NavbarAnchor>
                  <FormattedMessage defaultMessage="Stories" id="Ldxym4" />
                </NavbarAnchor>
              </Link>
            </Box>
            <Box as="li">
              <NavbarPopoverMenu />
            </Box>
          </Stack>
        </nav>
        <nav>
          <Box display="flex" alignItems="center" ml="auto">
            {user ? (
              <>
                <Flex mr={3}>
                  <UserNotifications />
                </Flex>

                <UserProfileDropdown user={user} />
              </>
            ) : (
              <JoinButtons ml={4} />
            )}
          </Box>
        </nav>
      </Box>
    </Box>
  );
}

interface INavbarAnchorProps extends AnchorProps {
  children: ReactNode;
}

const NavbarAnchor = forwardRef<HTMLAnchorElement, INavbarAnchorProps>(({ children, ...props }, ref) => {
  return (
    <Anchor color="gray.100" fontWeight="semibold" fontSize="lg" ref={ref} {...props}>
      {children}
    </Anchor>
  );
});

NavbarAnchor.displayName = "NavbarAnchor";
