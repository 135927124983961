import { mediumGray, text } from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import get from "lodash/fp/get";
import React from "react";
import fullName from "~/src/components/notifications/fullName";
import NotificationBox from "~/src/components/notifications/NotificationBox";
import NotificationTimeAgo from "~/src/components/notifications/NotificationTimeAgo";
import ProducerNames from "~/src/components/notifications/ProducerNames";

interface INotificationProps {
  tag?: any;
  pathToActor?: string;
  pathToActorName?: string;
  pathToAvatar: string;
  avatarName?: string;
  children: any;
  to?: string;
  notification: Record<string, any>;
  group?: {
    totalCount: number;
    notifications: Record<string, any>[];
  } | null;
}

export default function Notification({
  tag = "div",
  notification,
  group,
  pathToActor,
  pathToActorName,
  pathToAvatar,
  avatarName,
  children,
  ...props
}: INotificationProps) {
  let avatarUrl;
  let actorName;

  // if (!pathToActorName && !pathToActor) {
  //   throw new Error("You must provide either `pathToActorName` or `pathToActor`");
  // }

  if (group && group.notifications.length > 0) {
    const firstOfGroup = group.notifications[0];
    avatarUrl = get(pathToAvatar, firstOfGroup);

    if (pathToActorName) {
      actorName = get(pathToActorName, firstOfGroup);
    } else if (pathToActor) {
      actorName = fullName(get(pathToActor, firstOfGroup));
    }
  } else {
    avatarUrl = get(pathToAvatar, notification);
    if (pathToActorName) {
      actorName = get(pathToActorName, notification);
    } else if (pathToActor) {
      actorName = fullName(get(pathToActor, notification));
    }
  }

  const isUnread = !notification.readAt;

  return (
    <NotificationBox tag={tag} unread={isUnread} {...props}>
      <div
        css={{
          marginRight: 15,
        }}
      >
        <ProfilePicture
          alt={actorName ? `Profile picture of ${actorName}` : "Profile picture"}
          name={avatarName || actorName || "Govlaunch"}
          image={avatarUrl}
          xsmall={true}
        />
      </div>

      <div>
        <p
          css={{
            margin: 0,
            color: mediumGray,
            wordBreak: "break-word",
          }}
        >
          {(pathToActorName || pathToActor) && (
            <span
              css={{
                color: text,
                lineHeight: "15px",
              }}
            >
              <ProducerNames
                pathToProducer={pathToActor}
                pathToNameProducer={pathToActorName}
                notification={notification}
                group={group || null}
              />
            </span>
          )}

          {children}
        </p>

        <NotificationTimeAgo time={notification.createdAt} />
      </div>
    </NotificationBox>
  );
}
