import { ReactElement, useEffect, useState } from "react";

interface ITwoPassRenderProps {
  children: (document: Document) => ReactElement | null;
}

export default function TwoPassRender({ children }: ITwoPassRenderProps) {
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setIsClient(typeof document !== "undefined");
  }, []);

  return isClient ? children(document) : null;
}
