import React from "react";
import { FormattedRelativeTime } from "react-intl";
import moment from "moment";

interface IIntlRelativeTimeProps {
  value: any;
}

export default function IntlRelativeTime({ value }: IIntlRelativeTimeProps) {
  const { time, unit } = getTimeAndUnit(value);
  return <FormattedRelativeTime value={time} unit={unit} />;
}

type TTimeAndUnit = {
  time: number;
  unit: "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year" | undefined;
};

function getTimeAndUnit(value: any): TTimeAndUnit {
  const deltaYears = moment().diff(value, "year");
  const deltaMonths = moment().diff(value, "month");
  const deltaDays = moment().diff(value, "day");
  const deltaHours = moment().diff(value, "hour");
  const deltaMinutes = moment().diff(value, "minute");
  const deltaSeconds = moment().diff(value, "second");

  if (deltaYears > 0) {
    return {
      time: -deltaYears,
      unit: "year",
    };
  }

  if (deltaMonths > 0) {
    return {
      time: -deltaMonths,
      unit: "month",
    };
  }

  if (deltaDays > 0) {
    return {
      time: -deltaDays,
      unit: "day",
    };
  }

  if (deltaHours > 0) {
    return {
      time: -deltaHours,
      unit: "hour",
    };
  }

  if (deltaMinutes > 0) {
    return {
      time: -deltaMinutes,
      unit: "minute",
    };
  }

  return {
    time: -deltaSeconds,
    unit: "second",
  };
}
