import { useApolloClient } from "@apollo/client";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, Button } from "@govlaunch/web";
import { useRouter } from "next/router";
import { useContext, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { OptionProps } from "react-select/dist/declarations/src";
import { AuthContext, IAuthContext } from "~/components/auth/Auth";
import AutoCompleteUsersQuery from "~/components/dev/queries/AutoCompleteUsersQuery";
import ImpersonateMutation from "~/components/dev/queries/ImpersonateMutation";
import {
  IImpersonateMutation,
  IImpersonateMutationVariables,
} from "~/components/dev/queries/__generated__/ImpersonateMutation.generated";
import TwoPassRender from "~/components/TwoPassRender";
import {
  IAutoCompleteUsersQuery,
  IAutoCompleteUsersQueryVariables,
} from "./queries/__generated__/AutoCompleteUsersQuery.generated";

type TUserOption = IAutoCompleteUsersQuery["autoCompleteUsers"][0];

const UserOption = ({
  innerRef,
  innerProps,
  data,
  getStyles,
  ...props
}: OptionProps<{ label: string; value: TUserOption }>) => {
  const tagColors: Record<TUserOption["__typename"], { textColor?: string; bgColor: string }> = {
    GovernmentUser: { textColor: "white", bgColor: "blue.500" },
    PendingUser: { textColor: "white", bgColor: "orange.500" },
    VendorUser: { textColor: "white", bgColor: "cyan.500" },
  };
  return (
    <div ref={innerRef} {...innerProps} css={getStyles("option", props as any)}>
      <Box display="flex" alignItems="center">
        <ProfilePicture size={32} image={data.value.avatar} name={data.value.fullName} />
        <Box ml={2}>
          <Box fontSize={14} fontWeight="bold">
            {data.value.fullName}
          </Box>
          <Box fontSize={12} color="grey.400">
            {data.value.email}
          </Box>
          <Box
            fontSize={9}
            color={tagColors[data.value.__typename].textColor || "grey.400"}
            fontWeight="bold"
            backgroundColor={tagColors[data.value.__typename].bgColor || "cyan.400"}
            as="span"
            p={1}
            borderRadius={4}
          >
            {data.value.__typename}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default function ImpersonateBar() {
  const router = useRouter();
  const selectInputRef = useRef<any>();
  const [searchQuery, setSearchQuery] = useState("");
  const apolloClient = useApolloClient();
  const { login } = useContext<IAuthContext>(AuthContext);
  const [isImpersonateToolbarClosed, setIsImpersonateToolbarClosed] = useState(() => {
    if (router.query["impersonate-bar"] !== undefined) {
      return false;
    }
    if (typeof window !== "undefined") {
      return localStorage.getItem("impersonateToolbarClosed") === "true";
    }
    return true;
  });

  const searchForUsers = async (inputValue: string) => {
    const { data } = await apolloClient.query<IAutoCompleteUsersQuery, IAutoCompleteUsersQueryVariables>({
      query: AutoCompleteUsersQuery,
      variables: {
        searchQuery: inputValue,
      },
    });
    return data.autoCompleteUsers.map((user) => {
      return {
        label: user.fullName,
        value: user,
      };
    });
  };
  const onChange = async (option: { label: string; value: TUserOption }) => {
    setSearchQuery("");
    const { data } = await apolloClient.mutate<IImpersonateMutation, IImpersonateMutationVariables>({
      mutation: ImpersonateMutation,
      variables: {
        userId: option.value._id,
      },
    });
    if (data && data.impersonate && data.impersonate.token) {
      login(data.impersonate.token);
    }
  };
  if (!["qa", "development"].includes(process.env.GOVLAUNCH_ENV || "")) {
    return null;
  }

  return (
    <TwoPassRender>
      {() => {
        if (localStorage.getItem("impersonateToolbarClosed") === "true" && isImpersonateToolbarClosed) {
          return null;
        }
        return (
          <Box backgroundColor="blue.200" p={4}>
            <Box as="div" display="flex" alignItems="center">
              <Box as="h2" fontWeight="bold" color="blue.800">
                <Box fontSize={16}>Govlaunch QA</Box>
                <Box
                  fontSize={14}
                  color="orange.900"
                  backgroundColor="orange.100"
                  px={2}
                  borderRadius={4}
                  textAlign="center"
                >
                  Impersonate
                </Box>
              </Box>
              <Box ml={4} width={400}>
                <AsyncSelect
                  id="search-users"
                  ref={selectInputRef}
                  cacheOptions={false}
                  loadOptions={searchForUsers as any}
                  placeholder="Search for users to impersonate..."
                  onChange={onChange as any}
                  value={null}
                  inputValue={searchQuery}
                  onInputChange={setSearchQuery}
                  escapeClearsValue={true}
                  isClearable={true}
                  components={{
                    Option: UserOption,
                  }}
                />
              </Box>
              <Box>
                <Button
                  color="red.800"
                  onClick={() => {
                    localStorage.setItem("impersonateToolbarClosed", "true");
                    setIsImpersonateToolbarClosed(true);
                    router.replace(router.pathname, {}, { shallow: true });
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        );
      }}
    </TwoPassRender>
  );
}
