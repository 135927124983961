import { text } from "@govlaunch/palette";
import get from "lodash/fp/get";
import React from "react";
import { FormattedMessage } from "react-intl";
import Notification from "~/src/components/notifications/Notification";
import { INotification } from "~/src/components/notifications/types";

type INotificationComponentProps = {
  notification: INotification;
  group: {
    totalCount: number;
    notifications: Record<string, any>[];
  };
};

export function ReportCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in <highlight>{text}</highlight>" id="3dqVpu"
        values={{
          text: get("payload.report.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ReportCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {"  "}
      <FormattedMessage
        defaultMessage="replied to your comment in <highlight>{text}</highlight>" id="ptbt+B"
        values={{
          text: get("payload.report.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented in your product <highlight>{text}</highlight>" id="4pshmz"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a comment on <highlight>{text}</highlight>" id="0f3EBg"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your comment in product <highlight>{text}</highlight>" id="20ja7d"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in <highlight>{text}</highlight>" id="3dqVpu"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your product <highlight>{text}</highlight>" id="Zqp8jY"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function QuestionComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="answered your question <highlight>{text}</highlight>" id="jyBJ+O"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function QuestionCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a answer on <highlight>{text}</highlight>" id="WvKbw0"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function QuestionCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your answer on question <highlight>{text}</highlight>" id="p27pk2"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function QuestionCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your answer in <highlight>{text}</highlight>" id="l0BdvL"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductRecommendation({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.recommendedBy"
      pathToAvatar="payload.recommendedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="recommended <highlight>{name}</highlight> on your question <highlight>{title}</highlight>" id="2BA/os"
        values={{
          name: get("payload.product.name", notification),
          title: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProductRecommendationUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted a recommendation on your question for <highlight>{text}</highlight>" id="bjyyDz"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function RecommendationEndorsementUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="marked your recommendation as helpful on question <highlight>{text}</highlight>" id="n81krv"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function RecommendationEndorsementComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented your recommendation on question <highlight>{text}</highlight>" id="HxFc8q"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function RecommendationEndorsementCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a comment on question <highlight>{text}</highlight>" id="BIuJLD"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function RecommendationEndorsementCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your recommendation comment on question <highlight>{text}</highlight>" id="eYHlmn"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function RecommendationEndorsementCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment on question <highlight>{text}</highlight>" id="+m0gml"
        values={{
          text: get("payload.question.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ResourceComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented in your resource <highlight>{text}</highlight>" id="PYazWo"
        values={{
          text: get("payload.resource.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ResourceCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a comment on <highlight>{text}</highlight>" id="0f3EBg"
        values={{
          text: get("payload.resource.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ResourceCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your comment in resource <highlight>{text}</highlight>" id="4b7TCJ"
        values={{
          text: get("payload.resource.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ResourceCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in <highlight>{text}</highlight>" id="3dqVpu"
        values={{
          text: get("payload.resource.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ResourceUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your resource <highlight>{text}</highlight>" id="TTJ1KE"
        values={{
          text: get("payload.resource.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented in your story <highlight>{text}</highlight>" id="Znr3Rd"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProjectComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented in your project <highlight>{text}</highlight>" id="kycEjx"
        values={{
          text: get("payload.project.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a comment on <highlight>{text}</highlight>" id="0f3EBg"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your comment in story <highlight>{text}</highlight>" id="nJMmR+"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProjectCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your comment in project <highlight>{text}</highlight>" id="kta8sr"
        values={{
          text: get("payload.project.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in <highlight>{text}</highlight>" id="3dqVpu"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProjectCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in <highlight>{text}</highlight>" id="3dqVpu"
        values={{
          text: get("payload.project.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your story <highlight>{text}</highlight>" id="TFBH3r"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ProjectUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your project <highlight>{text}</highlight>" id="jUzr7z"
        values={{
          text: get("payload.project.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function PostUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your post <highlight>{text}</highlight>" id="itdfvy"
        values={{
          text: get("payload.post.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadComment({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.commentedBy"
      pathToAvatar="payload.commentedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="commented in your thread <highlight>{text}</highlight>" id="AAQHgP"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadCommentMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in a comment on <highlight>{text}</highlight>" id="0f3EBg"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadCommentReply({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.repliedBy"
      pathToAvatar="payload.repliedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="replied your comment in thread <highlight>{text}</highlight>" id="c46b1q"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadCommentUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your comment in thread <highlight>{text}</highlight>" id="gkgTI4"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadMention({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.mentionedBy"
      pathToAvatar="payload.mentionedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="mentioned you in <highlight>{text}</highlight>" id="bkWtrs"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function ThreadUpvote({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.upvotedBy"
      pathToAvatar="payload.upvotedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="upvoted your thread <highlight>{text}</highlight>" id="PjBG2t"
        values={{
          text: get("payload.thread.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function StoryWasEdited({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.editedBy"
      pathToAvatar="payload.editedBy.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="edited the Story <highlight>{text}</highlight>" id="E9F0iz"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function AddedAsCollaborator({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.story.government.logo">
      {" "}
      <FormattedMessage
        defaultMessage="You have been added as collaborator on Story <highlight>{text}</highlight>" id="KG6Lbn"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function AddedAsAuthor({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.story.government.logo">
      {" "}
      <FormattedMessage
        defaultMessage="You have been added as author on Story <highlight>{text}</highlight>" id="KzY+OS"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function WelcomeToGroup({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.logo">
      <FormattedMessage
        defaultMessage="Welcome to <highlight>{text}</highlight>!" id="p/S0tp"
        values={{
          text: get("payload.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function AccessRequestAccepted({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.logo">
      <FormattedMessage
        defaultMessage="Congratulations! Your access is now granted for <highlight>{text}</highlight>" id="SBcMuN"
        values={{
          text: get("payload.account.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function AccessRequestDeclined({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.logo">
      <FormattedMessage
        defaultMessage="Your access was declined for <highlight>{text}</highlight>. If you feel this was an error, please <mailto>contact us</mailto>" id="oC9aem"
        values={{
          text: get("payload.account.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
          mailto: (text: any) => <a href="mailto:hello@govlaunch.com">{text}</a>,
        }}
      />
    </Notification>
  );
}

export function AccountVerified({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.logo">
      <FormattedMessage defaultMessage="Your account is now verified on Govlaunch" id="fpKN1k" />
    </Notification>
  );
}

export function AccountUnverified({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification {...props} notification={notification} pathToAvatar="payload.logo">
      <FormattedMessage
        defaultMessage="We couldn't verify your account on Govlaunch. If you think that was a mistake, please <mailto>contact us</mailto>" id="BPaXXZ"
        values={{
          mailto: (text: any) => <a href="mailto:hello@govlaunch.com">{text}</a>,
        }}
      />
    </Notification>
  );
}

function HighlightNotificationPart(props: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      {...props}
      css={{
        color: text,
        lineHeight: "15px",
      }}
    />
  );
}

export function ProductApproved({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToAvatar="payload.product.logo"
      avatarName={get("payload.product.name", notification)}
    >
      <FormattedMessage
        defaultMessage="Your product <highlight>{text}</highlight> has been approved 🎉" id="+R2M9l"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

// -- Invites / Collaborators ------
export function JoinedAsCollaboratorOnProject({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.collaborator"
      pathToAvatar="payload.collaborator.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined as a collaborator on Project <highlight>{text}</highlight>" id="oAqqwr"
        values={{
          text: get("payload.project.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function JoinedAsCollaboratorOnStory({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.collaborator"
      pathToAvatar="payload.collaborator.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined as a collaborator on Story <highlight>{text}</highlight>" id="g4R3LD"
        values={{
          text: get("payload.story.title", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function JoinedAsGovernmentMember({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.member"
      pathToAvatar="payload.member.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined as a member of <highlight>{text}</highlight>" id="gf6EHX"
        values={{
          text: get("payload.government.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function JoinedAsVendorMember({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActor="payload.member"
      pathToAvatar="payload.member.avatarUrl"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined as a member of <highlight>{text}</highlight>" id="gf6EHX"
        values={{
          text: get("payload.vendor.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function GovernmentAcceptedGroupRequest({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActorName="payload.government.name"
      pathToAvatar="payload.group.logo"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined group <highlight>{text}</highlight>" id="RfPGNM"
        values={{
          text: get("payload.group.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function VendorAcceptedGroupRequest({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActorName="payload.vendor.name"
      pathToAvatar="payload.group.logo"
    >
      {" "}
      <FormattedMessage
        defaultMessage="joined group <highlight>{text}</highlight>" id="RfPGNM"
        values={{
          text: get("payload.group.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function GovernmentVerifiedProject({ notification, group, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      group={group}
      notification={notification}
      pathToActorName="payload.government.name"
      pathToAvatar="payload.group.logo"
    >
      {" "}
      <FormattedMessage
        defaultMessage="{totalCount, plural, =0 {has} =1 {has} other {have}} verified <highlight>{text}</highlight> Project" id="3e/FM8"
        values={{
          text: get("payload.group.name", notification),
          totalCount: group ? group.totalCount : 0,
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function GovernmentVerifiedVendorProject({ notification, group, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      group={group}
      notification={notification}
      pathToActorName="payload.government.name"
      pathToAvatar="payload.vendor.logo"
    >
      {" "}
      <FormattedMessage
        defaultMessage="{totalCount, plural, =0 {has} =1 {has} other {have}} verified <highlight>{text}</highlight> Project" id="3e/FM8"
        values={{
          text: get("payload.vendor.name", notification),
          totalCount: group ? group.totalCount : 0,
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function VendorVerifiedProject({ notification, group, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      group={group}
      notification={notification}
      pathToActorName="payload.vendor.name"
      pathToAvatar="payload.vendor.logo"
    >
      {" "}
      <FormattedMessage
        defaultMessage="{totalCount, plural, =0 {has} =1 {has} other {have}} verified <highlight>{text}</highlight> Project" id="3e/FM8"
        values={{
          text: get("payload.group.name", notification),
          totalCount: group ? group.totalCount : 0,
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function GovernmentAcceptedProductInvitation({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActorName="payload.government.name"
      pathToAvatar="payload.product.logo"
      avatarName={get("payload.product.name", notification)}
    >
      {" "}
      <FormattedMessage
        defaultMessage="accepted your invitation to <highlight>{text}</highlight>" id="dGFLZZ"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}

export function GovernmentDeniedProductInvitation({ notification, ...props }: INotificationComponentProps) {
  return (
    <Notification
      {...props}
      notification={notification}
      pathToActorName="payload.government.name"
      pathToAvatar="payload.product.logo"
      avatarName={get("payload.product.name", notification)}
    >
      {" "}
      <FormattedMessage
        defaultMessage="denied your invitation to <highlight>{text}</highlight>" id="tO5tup"
        values={{
          text: get("payload.product.name", notification),
          highlight: (text: any) => {
            return <HighlightNotificationPart>{text}</HighlightNotificationPart>;
          },
        }}
      />
    </Notification>
  );
}
