import * as palette from "@govlaunch/palette";
import { Avatar } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import * as Types from "~/types/types";
import SearchContext from "~/components/search/SearchContext";
import { Margin } from "~/components/spacings";
import HighlightTags from "~/components/searchbar/results/HighlightTags";
import HighlightTitle from "~/components/searchbar/results/HighlightTitle";
import ResultItem from "~/components/searchbar/results/ResultItem";

interface IProductResultItem {
  product: Pick<Types.IProduct, "slug" | "name" | "logo" | "tags" | "isDisruptor">;
  highlighted: boolean;
  onClick?: () => void;
}

const ProductResultItem: React.FunctionComponent<IProductResultItem> = ({ product, highlighted, onClick }) => {
  return (
    <SearchContext.Consumer>
      {(query) => (
        <Link href={`/products/${product.slug}`} passHref={true}>
          <ResultItem onClick={onClick} highlighted={highlighted}>
            {product.isDisruptor ? (
              <div
                css={{
                  position: "relative",
                }}
              >
                <Avatar
                  size="md"
                  src={product.logo}
                  name={product.name}
                  variant={product.isDisruptor ? "disruptor" : null}
                />
              </div>
            ) : (
              <Avatar
                size="md"
                src={product.logo}
                name={product.name}
                variant={product.isDisruptor ? "disruptor" : null}
              />
            )}

            <Margin ml={12}>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  css={{
                    margin: 0,
                    color: palette.text,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <HighlightTitle title={product.name || ""} query={query} />
                </h1>

                <Margin mt={4}>
                  <div
                    css={{
                      display: "flex",
                    }}
                  >
                    <span
                      css={{
                        color: palette.mediumGray,
                        marginRight: 4,
                        fontSize: 14,
                      }}
                    >
                      <FormattedMessage defaultMessage="Product" id="x/ZVlU" />{" "}
                      {product.tags.length > 0 && (
                        <>
                          · <HighlightTags tags={product.tags} query={query} />
                        </>
                      )}
                    </span>
                  </div>
                </Margin>
              </div>
            </Margin>
          </ResultItem>
        </Link>
      )}
    </SearchContext.Consumer>
  );
};

export default ProductResultItem;
