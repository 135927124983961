import React, { SVGProps, ReactElement, ReactNode, SyntheticEvent } from "react";
import { useToggle } from "react-use";
import { Box, Anchor, Text, Avatar } from "@govlaunch/web";
import {
  StoriesIcon,
  ProjectsIcon,
  ProductsIcon,
  SettingsIcon,
  ActionsIcon,
  MenuIcon,
  CloseIcon,
  UserIcon,
  LogOutIcon,
  ReferralsIcon,
  ActivityIcon,
  CollectionsIcon,
} from "~/src/components/navbar/icons";
import Terms from "~/components/Terms";
import Auth, { useSelfie } from "~/components/auth/Auth";
import getGovlaunchBaseUrl from "~/lib/utils/getGovlaunchBaseUrl";
import { ISelfieQuery } from "~/lib/queries/__generated__/SelfieQuery.generated";
import Referrals from "~/components/navbar/Referrals";
import mediaQueries from "~/lib/utils/mediaQueries";
import Link, { LinkProps } from "next/link";
import { useIntl, FormattedMessage } from "react-intl";
import useIntlPolicies from "~/src/shared/hooks/useIntlPolicies";

export default function MobileMenu() {
  const [isOpen, toggleOpen] = useToggle(false);
  const user = useSelfie();
  const intl = useIntl();

  return (
    <MenuContext.Provider value={{ isOpen, toggleOpen }}>
      <>
        <button
          type="button"
          aria-label={isOpen ? "Close menu" : "Open menu"}
          css={{
            background: "none",
            border: 0,
            outline: 0,
            cursor: "pointer",
            display: "inline-flex",
          }}
          onClick={() => toggleOpen()}
        >
          {!isOpen ? <MenuIcon /> : <CloseIcon />}
        </button>

        {isOpen && (
          <Box bg="gray.900" position="absolute" top="55px" left={-20} pt={3} width="305px" zIndex={101}>
            <Menu expanded={true}>
              <MenuItem
                text={intl.formatMessage({ defaultMessage: "Collections", id: "ulh3kf" })}
                icon={CollectionsIcon}
                href="/collections"
                as="/"
              />
              <MenuItem
                text={intl.formatMessage({ defaultMessage: "Projects", id: "UxTJRa" })}
                icon={ProjectsIcon}
                href="/projects"
                as="/projects"
              />
              <MenuItem
                text={intl.formatMessage({ id: "7NFfmz", defaultMessage: "Products" })}
                icon={ProductsIcon}
                href="/products"
                as="/products"
              />
              <MenuItem
                text={intl.formatMessage({ defaultMessage: "Stories", id: "Ldxym4" })}
                icon={StoriesIcon}
                href="/stories"
                as="/stories"
              />
            </Menu>
            {user && user.__typename === "VendorUser" && <VendorMenuSection user={user} />}
            {user && user.__typename === "GovernmentUser" && <GovernmentMenuSection user={user} />}
            {user && <UserMenuSection user={user} />}
            <AppMenuSection />
          </Box>
        )}
      </>
    </MenuContext.Provider>
  );
}

interface IMenuContextProps {
  isOpen: boolean;
  toggleOpen: (nextValue?: any) => void;
}

const MenuContext = React.createContext<IMenuContextProps>({
  isOpen: false,
  toggleOpen: () => {},
});

interface ICollapsibleMenuProps {
  image: string | null;
  name: string | null;
  children: ({ expanded, toggle }: { expanded: boolean; toggle: (arg: any) => any }) => ReactElement;
  defaultExpanded?: boolean;
}

function CollapsibleMenu({ image, name, children, defaultExpanded = false }: ICollapsibleMenuProps) {
  const [expanded, toggleExpanded] = useToggle(defaultExpanded);

  return (
    <div onClick={toggleExpanded}>
      <Box display="flex" alignItems="center" px={8} py={4} borderTop="1px" borderTopColor="gray.500">
        <Avatar size="sm" src={image} name={name} />

        <Text fontSize="lg" isTruncated={true} ml={3} fontWeight="bold" color="white">
          {name}
        </Text>

        <Chevron
          css={{
            marginLeft: "auto",
            paddingLeft: 8,
            transform: expanded ? "none" : "rotate(180deg)",
            transition: "transform 0.3s ease-out;",
          }}
          width={24}
        />
      </Box>

      <div>
        {children({
          expanded,
          toggle: toggleExpanded,
        })}
      </div>
    </div>
  );
}

function AppMenuSection() {
  const intl = useIntl();
  const policiesUrls = useIntlPolicies();

  return (
    <Box color="gray.200">
      <Menu expanded={true}>
        <div>
          <Link href="/about" passHref={true}>
            <Anchor color="gray.200" external="govlaunch" href="https://help.govlaunch.com" fontSize="md">
              <FormattedMessage defaultMessage="About" id="g5pX+a" />
            </Anchor>
          </Link>
        </div>

        <div>
          <Anchor color="gray.200" external="govlaunch" href="https://advertise.govlaunch.com/" fontSize="md">
            <FormattedMessage defaultMessage="Advertise" id="2OzlCZ" />
          </Anchor>
        </div>

        <div>
          <Anchor color="gray.200" external="govlaunch" href="https://help.govlaunch.com" fontSize="md">
            <FormattedMessage defaultMessage="Help" id="SENRqu" />
          </Anchor>
        </div>

        <div>
          <Link href="/podcast" passHref={true}>
            <Anchor color="gray.200" fontSize="md" rel="noreferrer noopener">
              <FormattedMessage defaultMessage="Podcast" id="uNMm97" />
            </Anchor>
          </Link>
        </div>

        <div>
          <Anchor color="gray.200" external="govlaunch" href="https://blog.govlaunch.com" fontSize="md">
            <FormattedMessage defaultMessage="Blog" id="tv5FG3" />
          </Anchor>
        </div>

        <div>
          <Terms
            title={intl.formatMessage({
              defaultMessage: "Terms of Service",
              id: "32rBNK",
            })}
            policyUrl={policiesUrls.termsAndConditions}
          >
            {(toggleOpen) => (
              <Anchor
                color="gray.200"
                href="#"
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleOpen();
                }}
                css={{
                  fontSize: 16,
                }}
              >
                <span>
                  <FormattedMessage defaultMessage="Terms of Service" id="32rBNK" />
                </span>
              </Anchor>
            )}
          </Terms>
        </div>

        <div>
          <Terms
            title={intl.formatMessage({
              defaultMessage: "Privacy Policy",
              id: "vx0nkZ",
            })}
            policyUrl={policiesUrls.privacyPolicy}
          >
            {(toggleOpen) => (
              <Anchor
                color="gray.200"
                href="#"
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleOpen();
                }}
                fontSize="md"
              >
                <span>
                  <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
                </span>
              </Anchor>
            )}
          </Terms>
        </div>

        <div>
          <Terms
            title={intl.formatMessage({
              defaultMessage: "Cookie Policy",
              id: "cA0kQY",
            })}
            policyUrl={policiesUrls.cookiePolicy}
          >
            {(toggleOpen) => (
              <Anchor
                color="gray.200"
                href="#"
                onClick={(event: SyntheticEvent) => {
                  event.preventDefault();
                  event.stopPropagation();
                  toggleOpen();
                }}
                css={{
                  fontSize: 16,
                }}
              >
                <span>
                  <FormattedMessage defaultMessage="Cookie Policy" id="cA0kQY" />
                </span>
              </Anchor>
            )}
          </Terms>
        </div>
      </Menu>
    </Box>
  );
}

function UserMenuSection({ user }: { user: NonNullable<ISelfieQuery["selfie"]> }) {
  const [isReferralsOpen, toggleReferralsOpen] = useToggle(false);
  const intl = useIntl();

  return (
    <>
      {isReferralsOpen && (
        <Referrals
          isOpen={isReferralsOpen}
          onRequestClose={() => toggleReferralsOpen()}
          referralUrl={`${getGovlaunchBaseUrl("portal")}?referral=${user._id}`}
        />
      )}

      <CollapsibleMenu defaultExpanded={true} image={user.avatar} name={user.fullName}>
        {({ expanded }) => (
          <Menu expanded={expanded}>
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "My Profile",
                id: "YzZa8+",
              })}
              icon={UserIcon}
              href={`/user?userSlug=${user.slug}`}
              as={`/@${user.slug}`}
            />
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "Settings",
                id: "D3idYv",
              })}
              icon={SettingsIcon}
              href={`/user?userSlug=${user.slug}&tab=settings`}
              as={`/@${user.slug}/settings`}
            />
            {user.groups.length > 0 && (
              <Box
                borderTop="1px"
                borderBottom="1px"
                borderTopColor="gray.500"
                borderBottomColor="gray.500"
                my={2}
                css={{
                  "> div": {
                    padding: "8px 0",
                  },
                }}
              >
                {user.groups.map((group) => (
                  <MenuItem
                    key={group._id}
                    text={group.name}
                    icon={() => <Avatar src={group.logo} name={group.name} size="xs" />}
                    href={`/group?groupSlug=${group.slug}`}
                  />
                ))}
              </Box>
            )}
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "Referrals",
                id: "CrJOJJ",
              })}
              icon={ReferralsIcon}
              onClick={toggleReferralsOpen}
            />
            <Auth>
              {({ logout }) => (
                <MenuItem
                  text={intl.formatMessage({
                    defaultMessage: "Log Out",
                    id: "H0JBH6",
                  })}
                  icon={LogOutIcon}
                  onClick={logout}
                />
              )}
            </Auth>
          </Menu>
        )}
      </CollapsibleMenu>
    </>
  );
}

function GovernmentMenuSection({
  user,
}: {
  user: Extract<NonNullable<ISelfieQuery["selfie"]>, { __typename: "GovernmentUser" }>;
}) {
  const intl = useIntl();
  const { government } = user;

  if (!government) {
    return null;
  }

  return (
    <CollapsibleMenu image={government.logo} name={government.name} defaultExpanded={true}>
      {({ expanded }) => (
        <Menu expanded={expanded}>
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Activity",
              id: "ZmlNQ3",
            })}
            icon={ActivityIcon}
            count={government.feedCount}
            href={`/government?governmentSlug=${government.slug}`}
            as={`/governments/${government.slug}`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Projects",
              id: "UxTJRa",
            })}
            icon={ProjectsIcon}
            count={government.projectsCount || 0}
            href={`/government?governmentSlug=${government.slug}&content=projects`}
            as={`/governments/${government.slug}/projects`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Products",
              id: "7NFfmz",
            })}
            icon={ProductsIcon}
            count={government.productsCount || 0}
            href={`/government?governmentSlug=${government.slug}&content=products`}
            as={`/governments/${government.slug}/products`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Actions",
              id: "wL7VAE",
            })}
            icon={ActionsIcon}
            count={government.actionsCount || 0}
            href={`/government?governmentSlug=${government.slug}&content=actions`}
            as={`/governments/${government.slug}/actions`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Settings",
              id: "D3idYv",
            })}
            icon={SettingsIcon}
            href={`/government?governmentSlug=${government.slug}&content=settings`}
            as={`/governments/${government.slug}/settings`}
          />
        </Menu>
      )}
    </CollapsibleMenu>
  );
}

function VendorMenuSection({
  user,
}: {
  user: Extract<NonNullable<ISelfieQuery["selfie"]>, { __typename: "VendorUser" }>;
}) {
  const intl = useIntl();
  const { company } = user;

  if (!company) {
    return null;
  }

  const userHasOwnerRights = user.role === "COMPANY_OWNER" || company.usersCanAccessAccountPage;

  return (
    <CollapsibleMenu image={company.logo} name={company.name} defaultExpanded={true}>
      {({ expanded }) => (
        <Menu expanded={expanded}>
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Activity",
              id: "ZmlNQ3",
            })}
            icon={ActivityIcon}
            count={company.feedCount}
            href={`/vendor?vendorSlug=${company.slug}&content=connect`}
            as={`/vendors/${company.slug}/connect`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Products",
              id: "7NFfmz",
            })}
            icon={ProductsIcon}
            count={company.productsCount || 0}
            href={`/vendor?vendorSlug=${company.slug}&content=products`}
            as={`/vendors/${company.slug}/products`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Projects",
              id: "UxTJRa",
            })}
            icon={ProjectsIcon}
            count={company.projectsCount || 0}
            href={`/vendor?vendorSlug=${company.slug}&content=projects`}
            as={`/vendors/${company.slug}/projects`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Stories",
              id: "Ldxym4",
            })}
            icon={StoriesIcon}
            count={company.storiesCount || 0}
            href={`/vendor?vendorSlug=${company.slug}&content=stories`}
            as={`/vendors/${company.slug}/stories`}
          />
          <MenuItem
            text={intl.formatMessage({
              defaultMessage: "Actions",
              id: "wL7VAE",
            })}
            icon={ActionsIcon}
            count={company.actionsCount}
            href={`/vendor?vendorSlug=${company.slug}&content=actions`}
            as={`/vendors/${company.slug}/actions`}
          />
          <MenuItemGroup
            text={intl.formatMessage({
              defaultMessage: "Settings",
              id: "D3idYv",
            })}
            icon={SettingsIcon}
            href="/vendor/[vendorSlug]/[[...content]]"
            as={`/vendors/${company.slug}/${userHasOwnerRights ? "account" : "edit-vendor"}`}
          >
            {userHasOwnerRights && (
              <MenuItem
                text={intl.formatMessage({
                  defaultMessage: "Account",
                  id: "TwyMau",
                })}
                href={`/vendor?vendorSlug=${company.slug}&content=account`}
                as={`/vendors/${company.slug}/account`}
              />
            )}
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "Edit Profile",
                id: "usAvMr",
              })}
              href={`/vendor?vendorSlug=${company.slug}&content=edit-vendor`}
              as={`/vendors/${company.slug}/edit-vendor`}
            />
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "Preview Profile",
                id: "Uve4oA",
              })}
              href={`/vendor?vendorSlug=${company.slug}&preview=1&maker=1`}
              as={`/vendors/${company.slug}?preview=1&maker=1`}
            />
            <MenuItem
              text={intl.formatMessage({
                defaultMessage: "Team",
                id: "wsUmh9",
              })}
              href={`/vendor?vendorSlug=${company.slug}&content=team`}
              as={`/vendors/${company.slug}/team`}
            />
          </MenuItemGroup>
        </Menu>
      )}
    </CollapsibleMenu>
  );
}

interface IMenuItemGroupProps extends IMenuItemProps, Omit<LinkProps, "children" | "href"> {
  children: any[];
  href?: string;
}

function MenuItemGroup({ children, ...props }: IMenuItemGroupProps) {
  return (
    <div>
      <MenuItem {...props} />

      <div
        css={mediaQueries({
          marginLeft: 24,
          paddingLeft: 12,
          "> div": {
            padding: 0,
          },
          "> div:not(:first-of-type)": {
            marginTop: 16,
          },
          marginTop: 8,
          textAlign: "left",
        })}
      >
        {children}
      </div>
    </div>
  );
}

interface IMenuProps {
  expanded: boolean;
  children: any[];
}

function Menu({ expanded, children, ...props }: IMenuProps) {
  return (
    <MenuContext.Consumer>
      {({ toggleOpen }) => (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            "> div": {
              padding: "8px 32px",
            },
            "> div:first-of-type": {
              paddingTop: 0,
            },
            "> div:last-child": {
              paddingBottom: 16,
            },
            transition: "max-height 0.3s ease-out;",
            overflow: "hidden",
            height: "auto",
            maxHeight: expanded ? 2000 : 0,
          }}
          {...props}
          onClick={() => {
            setTimeout(toggleOpen, 200);
          }}
        >
          {children}
        </div>
      )}
    </MenuContext.Consumer>
  );
}

interface IMenuItemProps {
  text: string;
  icon?: any | null;
  count?: number | null;
  disabled?: boolean;
  onClick?: (arg: any) => void;
}

function MenuItem({
  text,
  icon: Icon = null,
  onClick = () => {},
  count = null,
  disabled = false,
  href,
  ...props
}: IMenuItemProps & Omit<LinkProps, "children" | "href"> & { href?: string }) {
  if (href) {
    return (
      <div>
        <Link {...props} href={href} passHref={true}>
          <Anchor
            onClick={onClick}
            css={{
              display: "flex",
              alignItems: "center",
            }}
            color="gray.200"
            _hover={{
              color: "white",
            }}
          >
            {Icon && (
              <Box mr={3}>
                <Icon width={24} height={24} />
              </Box>
            )}
            <Text as="div" fontWeight="semibold" fontSize="md">
              {text} {!!count && count > 0 && !disabled && <CountBadge>{count > 99 ? "99+" : count}</CountBadge>}
            </Text>
          </Anchor>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <Anchor
        onClick={onClick}
        css={{
          display: "flex",
          alignItems: "center",
        }}
        color="gray.200"
        _hover={{
          color: "white",
        }}
      >
        {Icon && (
          <Box mr={3}>
            <Icon width={24} height={24} />
          </Box>
        )}
        <Text fontWeight="semibold" fontSize="md">
          {text} {!!count && count > 0 && !disabled && <CountBadge>{count > 99 ? "99+" : count}</CountBadge>}
        </Text>
      </Anchor>
    </div>
  );
}

interface ICountBadgeProps {
  children: ReactNode;
}

function CountBadge({ children }: ICountBadgeProps) {
  return (
    <Box
      bg="blue.600"
      fontSize="xs"
      fontWeight="semibold"
      width="16px"
      height="16px"
      borderRadius="full"
      textAlign="center"
      color="white"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      ml={2}
    >
      {children}
    </Box>
  );
}

function Chevron(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="11" viewBox="0 0 24 11" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2357 9.85051C23.7054 9.16809 23.5329 8.23413 22.8505 7.76447L12.5941 0.705643C12.071 0.345634 11.3779 0.354009 10.8637 0.726553L1.12009 7.78538C0.449212 8.2714 0.299358 9.20925 0.78538 9.88012C1.2714 10.551 2.20925 10.7009 2.88013 10.2148L11.7659 3.77747L21.1497 10.2357C21.8321 10.7054 22.7661 10.5329 23.2357 9.85051Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}
