import { Modal, ModalClose } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import React, { ReactElement } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { FormattedMessage, useIntl } from "react-intl";

interface IReferralsProps {
  referralUrl: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function Referrals({ referralUrl, isOpen, onRequestClose }: IReferralsProps): ReactElement | null {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} ariaHideApp={false} onRequestClose={onRequestClose}>
      <div
        css={{
          width: 370,
          padding: 20,
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h2
            css={{
              fontWeight: "bold",
              margin: 0,
            }}
          >
            <FormattedMessage defaultMessage="Referrals" id="CrJOJJ" />
          </h2>

          <ModalClose
            onClick={onRequestClose}
            style={{
              top: 0,
              right: 0,
              marginLeft: "auto",
              position: "relative",
              cursor: "pointer",
            }}
          />
        </div>

        <h4
          css={{
            fontWeight: 400,
            margin: 0,
            fontSize: 16,
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <FormattedMessage defaultMessage="Share your link" id="iHV7p1" />
        </h4>

        <p css={{ margin: 0, marginTop: 8, marginBottom: 8, color: palette.sealBlue }}>
          <FormattedMessage defaultMessage="Copy your personal referral link and share it with your colleagues." id="4tt8B7" />
        </p>

        <strong
          css={{
            textTransform: "uppercase",
            fontSize: 12,
            color: palette.darkGray,
          }}
        >
          <FormattedMessage defaultMessage="Referral link" id="zUezD5" />
        </strong>

        <div
          css={{
            marginTop: 12,
            marginBottom: 12,
            padding: 12,
            width: "100%",
            border: `solid 1px ${palette.lightSealBlue}`,
            borderRadius: 4,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          onClick={event => {
            event.preventDefault();

            if (window && window.getSelection()) {
              (window.getSelection() as Selection).selectAllChildren(event.currentTarget);
            }
          }}
        >
          {referralUrl}
        </div>

        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 48px)",
          }}
        >
          <TwitterShareButton
            url={referralUrl}
            title={intl.formatMessage({
              defaultMessage: 'Join to search, compare and review technology solutions using my link', id: 'cPZZLM',
            })}
            css={{
              cursor: "pointer",
              outline: 0,
            }}
          >
            <TwitterIcon round={true} size={42} />
          </TwitterShareButton>

          <FacebookShareButton
            url={referralUrl}
            css={{
              cursor: "pointer",
              outline: 0,
            }}
          >
            <FacebookIcon round={true} size={42} />
          </FacebookShareButton>

          <LinkedinShareButton
            url={referralUrl}
            css={{
              cursor: "pointer",
              outline: 0,
            }}
          >
            <LinkedinIcon round={true} size={42} />
          </LinkedinShareButton>
        </div>
      </div>
    </Modal>
  );
}
