import Downshift, { DownshiftProps } from "downshift";
import React from "react";
import { Box, BoxProps, palette } from "@govlaunch/web";
import DropdownArrowUp from "~/components/icons/DropdownArrowUp";
import DropdownArrowDown from "~/components/icons/DropdownArrowDown";
import ScreenReaderOnlyLabel from "~/components/accessibility/ScreenReaderOnlyLabel";

interface ILocale {
  id: string;
  apiLanguage: string;
  name: string;
  countryCode: string;
  disabled: boolean;
}

interface ILanguageSelectDropDownProps {
  locales: ILocale[];
}

export default function LanguageSelectDropDown({
  locales,
  ...props
}: ILanguageSelectDropDownProps & DownshiftProps<ILocale>) {
  return (
    <>
      <ScreenReaderOnlyLabel id="languageSelect-label" text="Select language" />
      <Downshift id="languageSelect" itemToString={(locale) => locale?.name || ""} {...props}>
        {({ getItemProps, highlightedIndex, isOpen, openMenu, closeMenu, selectedItem }) => {
          let globalIndex = -1;

          return (
            <div>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid",
                  borderColor: isOpen ? palette.blue[500] : palette.gray[600],
                  borderRadius: 4,
                }}
              >
                <Box
                  as="button"
                  position="relative"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor={palette.white}
                  borderRadius={3}
                  minWidth={200}
                  padding={1}
                  onClick={() => {
                    if (isOpen) {
                      closeMenu();
                    } else {
                      openMenu();
                    }
                  }}
                >
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {selectedItem && (
                      <>
                        <FlagImage countryCode={selectedItem.countryCode} />
                        <span
                          css={{
                            fontSize: "1rem",
                          }}
                        >
                          {selectedItem?.name}
                        </span>
                      </>
                    )}
                  </div>
                  <DropdownArrow isOpen={isOpen} />
                </Box>
              </div>
              {isOpen && (
                <div css={{ position: "relative" }}>
                  <Box
                    position="absolute"
                    width="100%"
                    bottom={45}
                    left={0}
                    as="div"
                    boxShadow="lg"
                    border="1px solid"
                    borderColor="gray.200"
                    bg="white"
                    listStyleType="none"
                    maxHeight={200}
                    overflowY="auto"
                    borderRadius={3}
                    paddingTop={3}
                    css={{
                      "&::-webkit-scrollbar": { width: 10 },
                      "&::-webkit-scrollbar-track": { background: palette.gray[100] },
                      "&::-webkit-scrollbar-thumb": { background: palette.gray[300], borderRadius: 1 },
                      "&::-webkit-scrollbar-thumb:hover": { background: palette.gray[400] },
                    }}
                  >
                    {sortLocales(locales).map((locale) => {
                      globalIndex += 1;
                      return (
                        <LocaleSelectOption
                          locale={locale}
                          key={locale.id}
                          active={highlightedIndex === globalIndex}
                          {...getItemProps({
                            index: globalIndex,
                            item: locale,
                          })}
                        />
                      );
                    })}
                  </Box>
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    </>
  );
}

interface ILocaleSelectOptionProps extends BoxProps {
  locale: ILocale;
  active?: boolean;
}

function LocaleSelectOption({ locale, ...props }: ILocaleSelectOptionProps) {
  return (
    <Box
      as="li"
      _hover={{
        color: "blue.600",
      }}
      cursor="pointer"
      height={30}
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      marginBottom={3}
      {...props}
    >
      <FlagImage countryCode={locale.countryCode} />
      <span
        css={{
          fontSize: "1rem",
        }}
      >
        {locale.name}
      </span>
    </Box>
  );
}

interface IDropdownArrowProps {
  isOpen: boolean;
}

function DropdownArrow({ isOpen }: IDropdownArrowProps) {
  if (isOpen) {
    return <DropdownArrowUp width="14px" height="14px" marginRight={1} />;
  }

  return <DropdownArrowDown width="14px" height="14px" marginRight={1} fill={palette.gray[600]} />;
}

interface IFlagImageProps {
  countryCode: string;
}

function FlagImage({ countryCode }: IFlagImageProps) {
  return (
    <div
      css={{
        width: "30px",
        height: "30px",
        position: "relative",
        overflow: "hidden",
        borderRadius: "50%",
        padding: 0,
        border: "1px solid",
        borderColor: "#eee",
        marginLeft: "0.75rem",
        marginRight: "0.75rem",
      }}
    >
      <img
        src={`https://static.govlaunch.com/flags/squared/${countryCode}.svg`}
        alt={`${countryCode} flag`}
        height="100%"
        css={{
          display: "block",
          position: "absolute",
          height: "100%",
          width: "auto",
          marginLeft: "-5px",
          maxWidth: "200px !important",
        }}
      />
    </div>
  );
}

function sortLocales(locales: ILocale[]) {
  const sorted = locales.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  sorted.forEach((locale, index) => {
    if (locale.id === "en-US") {
      sorted.splice(index, 1);
      sorted.unshift(locale);
    }
  });

  return sorted;
}
