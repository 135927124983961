import styled from "@emotion/styled";
import { greenSuccess, lightestSealBlue } from "@govlaunch/palette";
import React, { ReactNode } from "react";

export const className = "govlaunch-notification-box";

// const createNotificationBox = (tag: keyof JSX.IntrinsicElements) =>
const createNotificationBox = (tag: any) =>
  styled(tag)(props => {
    return {
      minHeight: 60,
      boxSizing: "border-box",
      padding: "15px 10px",
      textDecoration: "none",
      fontSize: 12,
      backgroundColor: props.unread ? "rgba(229, 255, 246, 0.5)" : "#fff",
      borderTop: `1px solid ${lightestSealBlue}`,
      borderLeft: props.unread ? `3px solid ${greenSuccess}` : "3px solid transparent",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    };
  });

interface INotificationBoxProps {
  tag: any;
  unread: boolean;
  children: ReactNode;
}

export default function NotificationBox({ tag = "div", ...props }: INotificationBoxProps) {
  const Component = createNotificationBox(tag);
  return <Component {...props} className={className} />;
}
