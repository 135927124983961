import React from "react";
import * as palette from "@govlaunch/palette";
import HighlightWords from "react-highlight-words";
import escapeRegExp from "lodash/fp/escapeRegExp";

interface IHighlightTitle {
  title: string;
  query: string | null;
}

export default function HighlightTitle({ title, query }: IHighlightTitle) {
  return (
    <HighlightWords
      highlightStyle={{
        background: palette.lightestGray,
        color: palette.text,
        fontWeight: "bold",
      }}
      searchWords={[escapeRegExp(query || "")]}
      textToHighlight={title}
    />
  );
}
