import { LoadingIndicator } from "@govlaunch/core";
import { NotificationBell, NotificationCount, NotificationStream } from "~/src/components/notifications";
import * as palette from "@govlaunch/palette";
import last from "lodash/fp/last";
import React, { Fragment, ReactElement } from "react";
import { useToggle } from "react-use";
import VisibilitySensor from "react-visibility-sensor";
import { MobileScreen, MobileScreenBody, MobileScreenHeader } from "~/components/mobile";
import Notification from "~/components/notifications/Notification";
import { FormattedMessage } from "react-intl";

interface IMobileNotificationsProps {
  token: string | null;
  channels: string[];
}

export default function MobileNotifications({ token, channels }: IMobileNotificationsProps): ReactElement | null {
  const [isOpen, toggle] = useToggle(false);

  if (!token) {
    return null;
  }

  return (
    <NotificationStream token={token} channels={channels}>
      {({ notifications, unreadCount, loadMore, read, groups, loading, hasMore }: any) => (
        <>
          <NotificationBell onClick={toggle}>
            {unreadCount > 0 && <NotificationCount>{unreadCount}</NotificationCount>}
          </NotificationBell>
          <MobileScreen isOpen={isOpen}>
            {() => (
              <>
                <MobileScreenHeader onRequestClose={toggle}>
                  <FormattedMessage defaultMessage="Notifications" id="NAidKb" />
                </MobileScreenHeader>

                <MobileScreenBody
                  style={{
                    padding: 0,
                  }}
                >
                  {notifications.map(
                    (notification: any, index: number): ReactElement => (
                      <Fragment key={notification._id}>
                        <Notification
                          notification={notification}
                          group={groups[notification.group]}
                          onRead={({ notificationUrl }) => {
                            read(notification).then(() => {
                              close();
                              window.location.href = notificationUrl;
                            });
                          }}
                        />

                        {index === notifications.length - 1 && (
                          <VisibilitySensor
                            delayedCall={true}
                            intervalDelay={1200}
                            partialVisibility={false}
                            scrollCheck={true}
                            onChange={(isVisible: boolean) => {
                              if (isVisible && hasMore && !loading) {
                                const lastNotification = last(notifications);

                                if (lastNotification) {
                                  loadMore((lastNotification as any)._id);
                                }
                              }
                            }}
                          />
                        )}

                        {shouldDisplaySpinner(index, notifications.length) && hasMore && (
                          <div
                            css={{
                              backgroundColor: palette.white,
                              display: "flex",
                              height: 50,
                            }}
                          >
                            <LoadingIndicator margin="auto" />
                          </div>
                        )}
                      </Fragment>
                    ),
                  )}
                </MobileScreenBody>
              </>
            )}
          </MobileScreen>
        </>
      )}
    </NotificationStream>
  );
}

function shouldDisplaySpinner(index: number, length: number, size = 10): boolean {
  const currentPage = Math.ceil(length / size);
  const lastItemInCurrentPage = currentPage * 10 - 1;
  return index === lastItemInCurrentPage;
}
