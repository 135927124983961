import { Mutation } from "@apollo/client/react/components";
import React from "react";

import * as palette from "@govlaunch/palette";
import DismissActionCenter from "~/lib/mutations/DismissActionCenter";
import {
  IDismissActionCenterMutation,
  IDismissActionCenterMutationVariables,
} from "~/lib/mutations/__generated__/DismissActionCenter.generated";
import { useSelfie } from "~/components/auth/Auth";
import { FormattedMessage } from "react-intl";
import Link from "next/link";

function ActionCenterBanner() {
  const viewer = useSelfie();

  if (!viewer || viewer.__typename === "PendingUser" || !viewer.showActionCenterBanner) {
    return null;
  }

  return (
    <Mutation<IDismissActionCenterMutation, IDismissActionCenterMutationVariables> mutation={DismissActionCenter}>
      {(dismissActionCenter) => (
        <div
          css={{
            position: "relative",
            padding: "24px 32px",
            border: `solid 1px ${palette.primary}`,
            backgroundColor: "rgba(0, 113, 187, 0.06)",
            color: palette.primary,
            borderRadius: 5,
            width: "100%",
          }}
        >
          {viewer.__typename === "GovernmentUser" ? (
            <FormattedMessage defaultMessage="There are pending actions for your government" id="37gsbo" />
          ) : (
            <FormattedMessage defaultMessage="There are pending actions for your company" id="0x13xY" />
          )}{" "}
          <Link
            href={
              viewer.__typename === "GovernmentUser"
                ? "/governments/[governmentSlug]/[[...content]]"
                : "/vendors/[vendorSlug]/[[...content]]"
            }
            as={
              viewer.__typename === "GovernmentUser"
                ? `/governments/${viewer.government.slug}/actions`
                : `/vendors/${(viewer.company || {}).slug}/actions`
            }
            passHref={true}
          >
            <a
              css={{
                color: palette.primary,
                fontWeight: 600,
                ":hover": {
                  textDecoration: "underline !important",
                },
              }}
            >
              <FormattedMessage defaultMessage="Go to actions" id="fNDYFb" /> →
            </a>
          </Link>
          <span
            role="button"
            onClick={() => dismissActionCenter()}
            css={{
              position: "absolute",
              top: 12,
              right: 12,
              textTransform: "uppercase",
              fontSize: 12,
              letterSpacing: 0.5,
              color: palette.primary,
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            <FormattedMessage defaultMessage="DISMISS" id="J17wtF" />
          </span>
        </div>
      )}
    </Mutation>
  );
}

export default ActionCenterBanner;
