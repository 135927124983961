import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React from "react";
import ProgressiveImage from "react-progressive-image";
import * as Types from "~/types/types";
import SearchContext from "~/components/search/SearchContext";
import { Margin } from "~/components/spacings";
import HighlightTags from "~/components/searchbar/results/HighlightTags";
import HighlightTitle from "~/components/searchbar/results/HighlightTitle";
import ResultItem from "~/components/searchbar/results/ResultItem";
import { FormattedMessage } from "react-intl";

interface IStoryResultItem {
  story: Pick<Types.IStory, "slug" | "title" | "tags" | "verticalCover" | "medias"> & {
    government: Types.Maybe<
      Pick<Types.IGovernment, "_id" | "slug"> & {
        city: Pick<Types.ICity, "name" | "state">;
      }
    >;
  };
  highlighted: boolean;
  onClick?: () => void;
}

const StoryResultItem: React.FunctionComponent<IStoryResultItem> = ({ story, highlighted, onClick }) => {
  const governmentName =
    story.government && story.government.city && story.government.city.name
      ? `${story.government.city.name}, ${story.government.city.state}`
      : null;
  const image = (story.medias || []).find(media => media.type?.toUpperCase() === Types.IMediaType.Image);

  return (
    <SearchContext.Consumer>
      {query => (
        <Link href="/stories/[storySlug]" as={`/stories/${story.slug}`} passHref={true}>
          <ResultItem onClick={onClick} highlighted={highlighted}>
            <ProgressiveImage
              src={
                image
                  ? `https://imaginary-ssl.govlaunch.com/resize?quality=100&compression=0&width=326&height=224&url=${image.url}&force=true`
                  : ""
              }
              placeholder="/static/story/story-card-placeholder.png"
            >
              {(src: string) => {
                return (
                  <img
                    src={src}
                    height={34}
                    width={50}
                    alt={story.title || ""}
                    css={{
                      minWidth: 50,
                      minHeight: 34,
                      pointerEvents: "none",
                      borderRadius: 5,
                      objectFit: "cover",
                    }}
                  />
                );
              }}
            </ProgressiveImage>

            <Margin ml={12}>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  css={{
                    margin: 0,
                    color: palette.text,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <HighlightTitle title={story.title || ""} query={query} />
                </h1>

                <Margin mt={4}>
                  <span
                    css={{
                      color: palette.mediumGray,
                      marginRight: 4,
                      fontSize: 14,
                    }}
                  >
                    <FormattedMessage defaultMessage="Story" id="Bfq+7w" /> · <HighlightTags tags={story.tags} query={query} />{" "}
                    {governmentName ? ` · ${governmentName}` : ""}
                  </span>
                </Margin>
              </div>
            </Margin>
          </ResultItem>
        </Link>
      )}
    </SearchContext.Consumer>
  );
};

export default StoryResultItem;
