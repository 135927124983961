import React from "react";

const NotificationIcon = (props: React.SVGProps<any>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.3809 6.3614C20.1939 6.78647 23.2017 8.69358 23.2017 14.3C23.2017 20.2566 24.5574 21.3721 26.4367 22.9184C26.6198 23.069 26.8079 23.2238 27.0001 23.3875H5.00012C5.18978 23.2273 5.37522 23.0758 5.55574 22.9283C7.44739 21.3829 8.79856 20.279 8.79856 14.3115C8.79856 8.70507 11.8236 6.79222 14.6423 6.3614C14.6366 6.33267 14.6366 6.30395 14.6366 6.26949C14.6366 5.56294 15.2381 5 16.0001 5C16.7621 5 17.3866 5.56294 17.3866 6.26949C17.3866 6.28751 17.385 6.30395 17.3835 6.31965C17.3822 6.33398 17.3809 6.34769 17.3809 6.3614ZM18.9731 24.5879C18.807 25.9149 17.5465 27.058 16.011 27.058C14.4756 27.058 13.2094 25.9149 13.049 24.5879H18.9731Z"
      fill="#73849D"
    />
  </svg>
);

export default NotificationIcon;
