import { SvgBox, SvgBoxProps } from "@govlaunch/web";
import React from "react";

export default function DropdownArrow(props: SvgBoxProps) {
  return (
    <SvgBox width="14" height="6" viewBox="0 0 14 6" fill="none" {...props}>
      <path d="M7 6L0.0717959 1.30507e-06L13.9282 9.36995e-08L7 6Z" fill={props.fill || "#086BE5"} />
    </SvgBox>
  );
}
