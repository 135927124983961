import { NextRouter } from "next/router";
import queryString from "querystring";

export default function nextLinkHref(
  filesystemRoute: string,
  params: Record<string, string | string[] | number | null | undefined>,
) {
  const searchParams = queryString.stringify(params, "&");
  return searchParams ? `${filesystemRoute}?${searchParams}` : filesystemRoute;
}

export function appendNextLinkHrefParams(
  router: NextRouter,
  params: Record<string, string | string[] | null | undefined>,
) {
  return nextLinkHref(router.route, {
    ...router.query,
    ...params,
  });
}
