import { Anchor, Box, Button, Flex, PageGridContainer, Stack, Text } from "@govlaunch/web";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import { useToggle } from "react-use";
import mediaQueries from "~/components/mediaQueries";
import LanguageSelect from "~/src/components/intl/LanguageSelect";
import useIntlPolicies from "~/src/shared/hooks/useIntlPolicies";
import TermsIframeModal from "~/src/shared/components/TermsIframeModal";
import Link from "next/link";

export default function Footer() {
  const [isTermsOpen, toggleTerms] = useToggle(false);
  const [isPrivacyPolicyOpen, togglePrivacyPolicy] = useToggle(false);
  const [isCookiePolicyOpen, toggleCookiePolicy] = useToggle(false);
  const policiesUrls = useIntlPolicies();

  return (
    <>
      <Box bg="gray.700" py={[8, 8, "60px"]}>
        <PageGridContainer>
          <Flex
            justify="space-between"
            align={["flex-start", "flex-start", "center"]}
            direction={["column", "column", "row"]}
          >
            <Box order={[2, 2, 0]} width={["100%", "100%", "auto"]} mt={[5, 5, 0]} px={[12, 12, 0]}>
              <Box
                as="ul"
                color="white"
                display="flex"
                flexDirection={["column", "column", "row"]}
                listStyleType="none"
                fontWeight="semibold"
                fontSize="md"
                css={mediaQueries({
                  "& li:not(:first-of-type)": {
                    "&:before": {
                      content: [null, null, "'•'"],
                      marginRight: [null, null, 8],
                    },
                    marginLeft: [0, 0, 8],
                  },

                  "& li": {
                    "&:before": {
                      content: ["'•'", '""', '""'],
                      marginRight: ["8px", "8px", 0],
                    },
                  },
                })}
                whiteSpace="nowrap"
                mr={2}
              >
                {/*
                <Box as="li">
                  <Anchor color="white" external="govlaunch" href="https://about.govlaunch.com/" title="About">
                    About
                  </Anchor>
                </Box>
                */}
                <Box as="li">
                  <Link href="/about" passHref={true}>
                    <Anchor color="white" rel="noreferrer noopener" title="Podcast">
                      <FormattedMessage defaultMessage="About" id="g5pX+a" />
                    </Anchor>
                  </Link>
                </Box>
                <Box as="li">
                  <Anchor color="white" external="govlaunch" href="https://advertise.govlaunch.com/" title="Advertise">
                    <FormattedMessage defaultMessage="Advertise" id="2OzlCZ" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" external="govlaunch" href="https://help.govlaunch.com/en/" title="Help">
                    <FormattedMessage defaultMessage="Help" id="SENRqu" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" external="govlaunch" href="https://blog.govlaunch.com" title="Blog">
                    <FormattedMessage defaultMessage="Blog" id="tv5FG3" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" onClick={toggleTerms} href="#">
                    <FormattedMessage defaultMessage="Terms of Service" id="32rBNK" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" onClick={togglePrivacyPolicy} href="#">
                    <FormattedMessage defaultMessage="Privacy" id="cXBJ7U" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" onClick={toggleCookiePolicy} href="#">
                    <FormattedMessage defaultMessage="Cookie Policy" id="cA0kQY" />
                  </Anchor>
                </Box>
                <Box as="li">
                  <Anchor color="white" href="mailto:contact@govlaunch.com">
                    <FormattedMessage defaultMessage="Contact" id="zFegDD" />
                  </Anchor>
                </Box>
              </Box>

              <Text
                fontSize="md"
                fontWeight="semibold"
                color="white"
                mt={["50px", "50px", 4]}
                textAlign={["center", "center", "left"]}
              >
                &copy; {new Date().getFullYear()} Govlaunch Inc.
              </Text>

              <Box mt={4}>
                <LanguageSelect />
              </Box>
            </Box>

            <Flex
              align="center"
              mb="auto"
              width={["100%", "100%", "100%", "auto"]}
              direction={["column", "column", "column", "row"]}
              pb={["65px", "65px", 0, 0]}
              borderBottom={["1px", "1px", "none", "none"]}
              borderBottomColor="gray.100"
            >
              {false && (
                <Button
                  variant="solid"
                  bg="blue.600"
                  size="sm"
                  color="white"
                  data-intercom-target="SubscribeForNewsletter"
                  order={[1, 1, 1, 0]}
                >
                  <span
                    style={{
                      verticalAlign: "baseline",
                      marginRight: 5,
                      paddingTop: 1,
                    }}
                  >
                    ✉️
                  </span>
                  <FormattedMessage defaultMessage="Subscribe" id="gczcC5" />
                </Button>
              )}

              <Stack direction="row" spacing={2} ml={[0, 0, 0, 6]} mb={[6, 6, 4, 0]} order={[0, 0, 0, 1]}>
                <Anchor external="govlaunch" href="https://www.facebook.com/teamgovlaunch" aria-label="facebook">
                  <FacebookIcon size={32} round={true} />
                </Anchor>
                <Anchor external="govlaunch" href="https://twitter.com/govlaunch" aria-label="twitter">
                  <TwitterIcon size={32} round={true} />
                </Anchor>
                <Anchor external="govlaunch" href="https://www.linkedin.com/company/govlaunch/" aria-label="linkedin">
                  <LinkedinIcon size={32} round={true} />
                </Anchor>
              </Stack>
            </Flex>
          </Flex>
        </PageGridContainer>
      </Box>

      <TermsIframeModal
        title="Terms and Conditions"
        policyUrl={policiesUrls.termsAndConditions}
        isOpen={isTermsOpen}
        onClose={toggleTerms}
      />

      <TermsIframeModal
        title="Privacy Policy"
        policyUrl={policiesUrls.privacyPolicy}
        isOpen={isPrivacyPolicyOpen}
        onClose={togglePrivacyPolicy}
      />

      <TermsIframeModal
        title="Cookie Policy"
        policyUrl={policiesUrls.cookiePolicy}
        isOpen={isCookiePolicyOpen}
        onClose={toggleCookiePolicy}
      />
    </>
  );
}
