import { BlockBodyOverflow } from "@govlaunch/core";
import React, { ReactElement } from "react";

import { createPortal } from "react-dom";
import { useIntl } from "react-intl";

type IMobileScreenChildrenFunction = (isOpen: boolean) => ReactElement | null;

export function MobileScreen({
  isOpen,
  children,
  ...props
}: {
  isOpen: boolean;
  children: IMobileScreenChildrenFunction | ReactElement | null;
}): ReactElement | null {
  if (typeof document !== "undefined") {
    return createPortal(
      <div
        css={{
          position: "fixed",
          top: 0,
          left: isOpen ? 0 : "100%",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#ffffff",
          zIndex: 99000,
          visibility: isOpen ? "visible" : "hidden",
          transform: "translateZ(0)",
          overflow: "auto",
        }}
        {...props}
      >
        {isOpen && <BlockBodyOverflow />}
        {typeof children === "function" ? children(isOpen) : children}
      </div>,
      document.body,
    );
  }

  return null;
}

export function MobileScreenHeader({
  onRequestClose,
  children,
  ...props
}: {
  onRequestClose: () => void;
  children: any;
}): ReactElement {
  const intl = useIntl();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        borderBottom: "solid 1px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fafafa",
        color: "#666666",
        position: "relative",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <a
        href="#"
        aria-label={intl.formatMessage({
          defaultMessage: 'Close', id: 'rbrahO',
        })}
        css={{
          position: "absolute",
          role: "button",
          top: 0,
          left: 0,
          color: "inherit",
          fontSize: 16,
          textDecoration: "none",
          padding: 20,
        }}
        onClick={event => {
          event.preventDefault();
          onRequestClose();
        }}
      >
        <LeftArrow width={33} height={23} />
      </a>

      <div
        css={{
          flex: 1,
          marginRight: 20,
          marginLeft: 20,
          fontSize: 16,
          textAlign: "center",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  );
}

export function MobileScreenBody({ children, ...props }: { children: any; [key: string]: any }): ReactElement {
  return (
    <div
      {...props}
      css={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
}
const LeftArrow = (props: any) => (
  <svg width={32} height={23} {...props}>
    <path
      d="M10.273 1.009a1.112 1.112 0 0 1 1.587 0 1.12 1.12 0 0 1 0 1.571l-8.047 8.047h26.554c.619 0 1.127.492 1.127 1.111s-.508 1.127-1.127 1.127H3.813l8.047 8.032c.429.444.429 1.159 0 1.587a1.112 1.112 0 0 1-1.587 0L.321 12.532a1.12 1.12 0 0 1 0-1.571l9.952-9.952z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);
