import Popover from "@govlaunch/popover";
import React, { ReactElement } from "react";
import { Value } from "react-powerplug";
import NotificationBell from "~/src/components/notifications/NotificationBell";
import NotificationCount from "~/src/components/notifications/NotificationCount";
import NotificationStream, { NotificationStreamChildrenProps } from "~/src/components/notifications/NotificationStream";

interface INotificationPopoverChildrenProps {
  open: () => void;
  close: () => void;
}

interface INotificationPopoverProps {
  channels: string[];
  token: string | null;
  children: (props: NotificationStreamChildrenProps & INotificationPopoverChildrenProps) => ReactElement;
}

export default function NotificationPopover({ channels, token = null, children }: INotificationPopoverProps) {
  return (
    <NotificationStream token={token} channels={channels}>
      {({ notifications, unreadCount, loadMore, read, readAll, groups, loading, hasMore }) => (
        <Value initial={false}>
          {({ value: isOpen, set: setOpen }) => (
            <Popover
              placement="bottom"
              backgroundColor="#E3E6EB"
              borderColor="#E3E6EB"
              isOpen={isOpen}
              clickOutsideStrategy="accessibility"
              boxStyle={{
                boxShadow: "0 10px 40px 0 rgba(34, 35, 40, 0.2)",
                minWidth: 375,
                border: `1px solid #ADB6C3`,
              }}
              onClickOutside={() => setOpen(false)}
              render={() =>
                children({
                  notifications,
                  unreadCount,
                  loadMore,
                  read,
                  readAll,
                  groups,
                  loading,
                  hasMore,
                  open: () => setOpen(true),
                  close: () => setOpen(false),
                })
              }
            >
              {({ innerRef }: any) => (
                <NotificationBell innerRef={innerRef} onClick={() => setOpen(isOpen ? false : true)}>
                  {unreadCount > 0 && <NotificationCount>{unreadCount}</NotificationCount>}
                </NotificationBell>
              )}
            </Popover>
          )}
        </Value>
      )}
    </NotificationStream>
  );
}
