import * as palette from "@govlaunch/palette";
import ProfilePicture from "@govlaunch/profile-picture";
import { Box, CountryFlag, TCountryCode } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import * as Types from "~/types/types";
import SearchContext from "~/components/search/SearchContext";
import { Margin } from "~/components/spacings";
import HighlightTitle from "~/components/searchbar/results/HighlightTitle";
import ResultItem from "~/components/searchbar/results/ResultItem";

interface IGovernmentResultItem {
  government: Pick<Types.IGovernment, "_id" | "logo" | "slug" | "name"> & {
    country: string;
    city: Pick<Types.ICity, "type" | "country">;
  };
  highlighted: boolean;
  onClick?: () => void;
}

const GovernmentResultItem: React.FunctionComponent<IGovernmentResultItem> = ({ government, highlighted, onClick }) => {
  return (
    <SearchContext.Consumer>
      {(query) => (
        <Link
          href="/governments/[governmentSlug]/[[...content]]"
          as={`/governments/${government.slug}`}
          passHref={true}
        >
          <ResultItem onClick={onClick} highlighted={highlighted}>
            <Box position="relative">
              <ProfilePicture image={government.logo} size={50} name={government.name} />
              <Box position="absolute" bottom={1} right={-3}>
                <CountryFlag countryCode={government.city.country as TCountryCode} width={24} height={14} />
              </Box>
            </Box>

            <Margin ml={12}>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  css={{
                    margin: 0,
                    color: palette.text,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <HighlightTitle title={government.name || ""} query={query} />
                </h1>

                <Margin mt={4}>
                  <div
                    css={{
                      display: "flex",
                    }}
                  >
                    <span
                      css={{
                        color: palette.mediumGray,
                        marginRight: 4,
                        fontSize: 14,
                      }}
                    >
                      <FormattedMessage defaultMessage="Government" id="bh4rlK" /> · {government.city.type} · {government.country}
                    </span>
                  </div>
                </Margin>
              </div>
            </Margin>
          </ResultItem>
        </Link>
      )}
    </SearchContext.Consumer>
  );
};

export default GovernmentResultItem;
