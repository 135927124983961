import * as palette from "@govlaunch/palette";
import { getProjectEntityLogo, getProjectEntityName } from "@govlaunch/project";
import { Avatar } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import nextLinkHref from "~/src/utils/nextLinkHref";
import { IGovernment, IGroup, IProject } from "~/types/types";
import SearchContext from "~/components/search/SearchContext";
import { Margin } from "~/components/spacings";
import HighlightTags from "~/components/searchbar/results/HighlightTags";
import HighlightTitle from "~/components/searchbar/results/HighlightTitle";
import ResultItem from "~/components/searchbar/results/ResultItem";
import { FormattedMessage } from "react-intl";

type TProject =
  | ({ __typename: "GovernmentProject" } & Pick<IProject, "_id" | "audience" | "slug" | "tags" | "title"> & {
        government: Pick<IGovernment, "slug" | "name" | "logo">;
        objectID: string;
      })
  | ({ __typename: "GroupProject" } & Pick<IProject, "_id" | "audience" | "slug" | "tags" | "title"> & {
        group: Pick<IGroup, "slug" | "name" | "logo">;
        objectID: string;
      });

interface IProjectResultItem {
  project: TProject;
  highlighted: boolean;
  onClick?: () => void;
}

const ProjectResultItem: React.FunctionComponent<IProjectResultItem> = ({ project, highlighted, onClick }) => {
  return (
    <SearchContext.Consumer>
      {(query) => (
        <Link
          key={project.slug}
          href={nextLinkHref("project", { projectSlug: project.slug })}
          as={`/projects/${project.slug}`}
          passHref={true}
        >
          <ResultItem onClick={onClick} highlighted={highlighted}>
            <div
              css={{
                display: "inline-flex",
                width: 50,
                height: 50,
              }}
            >
              <Avatar name={getProjectEntityName(project)} src={getProjectEntityLogo(project)} size="md" />
            </div>

            <Margin ml={12}>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1
                  css={{
                    margin: 0,
                    color: palette.text,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <HighlightTitle title={project.title || ""} query={query} />
                </h1>

                <Margin mt={4}>
                  <span
                    css={{
                      color: palette.mediumGray,
                      marginRight: 4,
                      fontSize: 14,
                    }}
                  >
                    <FormattedMessage defaultMessage="Project" id="k36uSw" /> · <HighlightTags tags={project.tags} query={query} />{" "}
                    {project.__typename === "GovernmentProject" ? `· ${project.government.name}` : ""}
                  </span>
                </Margin>
              </div>
            </Margin>
          </ResultItem>
        </Link>
      )}
    </SearchContext.Consumer>
  );
};

export default ProjectResultItem;
