type Names = {
  firstName: string | null;
  lastName: string | null;
};

export default function fullName(names: Names | null) {
  if (!names) {
    return "";
  }

  return `${names.firstName || ""} ${names.lastName || ""}`.trim();
}
