import React, { ReactElement } from "react";

interface INotificationsOverflowProps {
  children: ReactElement;
  innerRef: React.Ref<HTMLDivElement>;
}

export default function NotificationsOverflow({ children, innerRef }: INotificationsOverflowProps) {
  return (
    <div
      ref={innerRef}
      css={{
        maxHeight: 550,
        overflowY: "auto",
      }}
    >
      {children}
    </div>
  );
}
