import { Box, BoxProps, PageGridContainer } from "@govlaunch/web";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import ActionCenterBanner from "~/components/actions/ActionCenterBanner";
import showActionCenterBanner from "~/components/actions/showActionCenterBanner";
import { useSelfie } from "~/components/auth/Auth";
import ImpersonateBar from "~/components/dev/ImpersonateBar";
import Alert from "~/components/makers/Alert";
import OverdueStickyNoticeBanner from "~/components/makers/OverdueStickyNoticeBanner";
import ScheduledToCancelStickyNoticeBanner from "~/components/makers/ScheduledToCancelStickyNoticeBanner";
import HreflangTags from "~/components/seo/HreflangTags";
import CompleteGovernmentProfileBanner from "~/components/user/CompleteGovernmentProfileBanner";
import CompleteProfileBanner from "~/components/user/CompleteProfileBanner";
import useIsMobile from "~/lib/hooks/useIsMobile";
import BottomNavbar from "~/src/components/navbar/BottomNavbar";
import DesktopNavbar from "~/src/components/navbar/DesktopNavbar";
import MobileNavbar from "~/src/components/navbar/MobileNavbar";
import Footer from "~/src/shared/components/Footer";

interface INavbarLayoutProps {
  children: ReactNode;
  withActionCenterBanner?: boolean;
  withOnboardingBanner?: boolean;
  withoutOverdueStickyNoticeBanner?: boolean;
  navbarProps?: BoxProps;
  isOnboarding?: boolean;
  isLoggingIn?: boolean;
  showBanners?: boolean;
}

export default function NavbarLayout({
  children,
  withActionCenterBanner = true,
  withOnboardingBanner = true,
  withoutOverdueStickyNoticeBanner = false,
  navbarProps = {},
  isOnboarding = false,
  isLoggingIn = false,
  showBanners = true,
  ...props
}: INavbarLayoutProps & BoxProps) {
  const isMobile = useIsMobile();
  const router = useRouter();
  const viewer = useSelfie();

  const showActionBanner = showBanners && withActionCenterBanner && showActionCenterBanner(viewer);

  // government profile banner should only appear if user is seeing the government profile or has already completed his profile
  const isGovernmentRoute = router?.pathname === "/government";

  const showGovernmentBanner =
    showBanners &&
    viewer &&
    viewer.__typename === "GovernmentUser" &&
    viewer.showCompleteGovernmentProfileBanner &&
    (isGovernmentRoute || !viewer.showOnboardingBanner);

  const showOverdueStickyBanner =
    showBanners &&
    viewer &&
    viewer.__typename === "VendorUser" &&
    viewer.company &&
    viewer.company.subscription &&
    viewer.company.subscription.__typename === "OverdueSubscription" &&
    !withoutOverdueStickyNoticeBanner;

  const showScheduledToCancelStickyNoticeBanner =
    showBanners &&
    viewer !== null &&
    viewer.__typename === "VendorUser" &&
    viewer.company !== null &&
    viewer.company.subscription !== null &&
    viewer.company.subscription.__typename === "ScheduledToCancelSubscription";

  const showCompleteProfileBanner =
    showBanners && withOnboardingBanner && viewer !== null && viewer.showOnboardingBanner;
  const showCompleteGovernmentProfileBanner = showBanners && withOnboardingBanner && showGovernmentBanner;

  const hasBannerToShow =
    showActionBanner ||
    showGovernmentBanner ||
    showOverdueStickyBanner ||
    showScheduledToCancelStickyNoticeBanner ||
    showCompleteProfileBanner ||
    showCompleteGovernmentProfileBanner;

  return (
    <>
      <HreflangTags />
      <ImpersonateBar />
      <Box minHeight={["calc(100vh + 375px)", "100vh", "100vh"]} display="flex" flexDirection="column">
        <Box pb={16} {...props}>
          <Box mb={isOnboarding || isLoggingIn ? [0, 0, 0, 0] : [4, 4, 4, 8]} {...navbarProps}>
            {isMobile ? <MobileNavbar /> : <DesktopNavbar />}
          </Box>
          <main>
            {viewer && !isOnboarding && (
              <PageGridContainer
                mt={[4, 4, 4, 8]}
                mb={[4, 4, 4, 8]}
                spacingY={3}
                css={{
                  "&:empty": {
                    display: "none",
                  },
                }}
              >
                {showActionBanner && <ActionCenterBanner />}

                {showCompleteProfileBanner && <CompleteProfileBanner />}

                {withOnboardingBanner && showGovernmentBanner && <CompleteGovernmentProfileBanner />}

                {viewer.__typename === "PendingUser" && viewer.viewerCouldNotJoinReason && (
                  <Alert>{viewer.viewerCouldNotJoinReason}</Alert>
                )}

                {showOverdueStickyBanner && <OverdueStickyNoticeBanner />}

                {viewer !== null &&
                  viewer.__typename === "VendorUser" &&
                  viewer.company !== null &&
                  viewer.company.subscription !== null &&
                  viewer.company.subscription.__typename === "ScheduledToCancelSubscription" && (
                    <ScheduledToCancelStickyNoticeBanner
                      vendorSlug={viewer.company.slug}
                      willCancelAt={viewer.company.subscription.willCancelAt}
                    />
                  )}
              </PageGridContainer>
            )}

            {hasBannerToShow && <Box mt="auto" zIndex={11} bg="gray.700" pb={[10, 10, 0]} />}

            {children}
          </main>
        </Box>

        <BottomNavbar />
        <footer css={{ marginTop: "auto" }}>
          <Box mt="auto" zIndex={11} bg="gray.700" pb={[10, 10, 0]}>
            <Footer />
          </Box>
        </footer>
      </Box>
    </>
  );
}
