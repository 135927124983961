import React, { SVGProps } from "react";

export function ActivityIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19 24H21L17.59 16L15.98 16.91L19 24Z" fill="currentColor" />
      <path d="M3 24H5L8.07 16.72L6.38 16L3 24Z" fill="currentColor" />
      <path
        d="M16.8901 22.0001H7.11008L6.11008 14.1101C5.97014 13.0078 5.97014 11.8923 6.11008 10.7901C6.54234 7.5519 8.02477 4.54487 10.3301 2.23009L12.0001 0.590088L13.6501 2.24009C15.9554 4.55487 17.4378 7.5619 17.8701 10.8001C18.01 11.9023 18.01 13.0178 17.8701 14.1201L16.8901 22.0001ZM8.69008 20.0001H15.3201L16.0601 13.8701C16.1773 12.9371 16.1773 11.9931 16.0601 11.0601C15.6151 8.1797 14.267 5.515 12.2101 3.45009L12.0001 3.21009L11.7601 3.45009C9.70318 5.515 8.35508 8.1797 7.91008 11.0601C7.79285 11.9931 7.79285 12.9371 7.91008 13.8701L8.69008 20.0001Z"
        fill="currentColor"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="currentColor"
      />
      <path d="M11 24H13L13 16H11V24Z" fill="currentColor" />
    </svg>
  );
}

export function ProjectsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 10H13V7H11V10H8V12H11V15H13V12H16V10Z" fill="currentColor" />
      <path d="M15 22H9V24H15V22Z" fill="currentColor" />
      <path
        d="M14.6 21H9.40004L7.64004 17.69C6.51998 16.9706 5.59931 15.9806 4.9631 14.8113C4.32689 13.642 3.99567 12.3312 4.00004 11C4.00004 8.87827 4.8429 6.84344 6.34319 5.34315C7.84348 3.84285 9.87831 3 12 3C14.1218 3 16.1566 3.84285 17.6569 5.34315C19.1572 6.84344 20 8.87827 20 11C20.0044 12.3312 19.6732 13.642 19.037 14.8113C18.4008 15.9806 17.4801 16.9706 16.36 17.69L14.6 21ZM10.6 19H13.4L14.85 16.27L15.09 16.13C15.9758 15.5979 16.7091 14.846 17.2189 13.9472C17.7287 13.0485 17.9978 12.0333 18 11C18 9.4087 17.3679 7.88258 16.2427 6.75736C15.1175 5.63214 13.5913 5 12 5C10.4087 5 8.88262 5.63214 7.7574 6.75736C6.63218 7.88258 6.00004 9.4087 6.00004 11C6.00403 12.0316 6.27392 13.0447 6.78366 13.9416C7.2934 14.8384 8.02576 15.5887 8.91004 16.12L9.15004 16.26L10.6 19Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}

export function CollectionsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30769 2C3.67521 2 3.07368 2.2244 2.64904 2.64904C2.2244 3.07368 2 3.67521 2 4.30769V7.38462V10.4615V19.6923C2 20.3248 2.2244 20.9263 2.64904 21.351C3.07368 21.7756 3.67521 22 4.30769 22H7H19.6923H20C21.1046 22 22 21.1046 22 20V19.6923V11.2308V10.4615V10V8.92308V7.38462C22 6.75214 21.7756 6.1506 21.351 5.72596C20.9263 5.30132 20.3248 5.07692 19.6923 5.07692H11.2308V4.30769C11.2308 3.67521 11.0064 3.07368 10.5817 2.64904C10.1571 2.2244 9.55556 2 8.92308 2H4.30769ZM20 8C20.1589 8 20.3134 8.01852 20.4615 8.05352V7.38462C20.4615 7.09402 20.377 6.92752 20.2632 6.8137C20.1494 6.69988 19.9829 6.61538 19.6923 6.61538H11.2308C10.8291 6.61538 10.418 6.46971 10.128 6.17969C9.83798 5.88966 9.69231 5.47863 9.69231 5.07692V4.30769C9.69231 4.01709 9.60781 3.8506 9.49399 3.73678C9.38017 3.62296 9.21368 3.53846 8.92308 3.53846H4.30769C4.01709 3.53846 3.8506 3.62296 3.73678 3.73678C3.62296 3.8506 3.53846 4.01709 3.53846 4.30769V7.38462V10.4615V19.6923C3.53846 19.9829 3.62296 20.1494 3.73678 20.2632C3.8506 20.377 4.01709 20.4615 4.30769 20.4615C4.59829 20.4615 4.76478 20.377 4.87861 20.2632C4.92616 20.2157 4.9686 20.1589 5.00189 20.0878C5.00064 20.0587 5 20.0294 5 20V10C5 8.89543 5.89543 8 7 8H20ZM14.9548 13.4977L13.5 11L12.0452 13.4977L9.22025 14.1094L11.1461 16.2648L10.855 19.1406L13.5 17.975L16.145 19.1406L15.8539 16.2648L17.7798 14.1094L14.9548 13.4977Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ProductsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20 10V20H4V10H20ZM22 8H2V22H22V8Z" fill="currentColor" />
      <path d="M20 5H4V7H20V5Z" fill="currentColor" />
      <path d="M18 2H6V4H18V2Z" fill="currentColor" />
    </svg>
  );
}

export function StoriesIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5 5V23H17L23 17V5H5ZM17 20.17V17H20.17L17 20.17ZM21 15H15V21H7V7H21V15Z" fill="currentColor" />
      <path d="M11 10H9V12H11V10Z" fill="currentColor" />
      <path d="M18 10H13V12H18V10Z" fill="currentColor" />
      <path d="M13 14H9V16H13V14Z" fill="currentColor" />
      <path d="M1 1V15.03V17H3H5V15.03H3V3H14V5H16V1H1Z" fill="currentColor" />
    </svg>
  );
}

export function SettingsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21 13V11H17.91C17.766 10.1121 17.4243 9.26797 16.91 8.53L19.09 6.35L17.66 4.93L15.47 7.11C14.732 6.59574 13.8879 6.25398 13 6.11V3H11V6.09C10.1121 6.23398 9.26797 6.57574 8.53 7.09L6.34 4.93L4.93 6.34L7.11 8.53C6.59574 9.26797 6.25398 10.1121 6.11 11H3V13H6.09C6.23398 13.8879 6.57574 14.732 7.09 15.47L4.93 17.66L6.34 19.07L8.52 16.89C9.25797 17.4043 10.1021 17.746 10.99 17.89V21H12.99V17.91C13.8779 17.766 14.722 17.4243 15.46 16.91L17.64 19.09L19.05 17.68L16.87 15.5C17.3843 14.762 17.726 13.9179 17.87 13.03H21V13ZM7.81 12C7.81 11.1713 8.05574 10.3612 8.51614 9.67216C8.97654 8.98312 9.63093 8.44608 10.3966 8.12894C11.1622 7.81181 12.0046 7.72884 12.8174 7.89051C13.6302 8.05218 14.3768 8.45124 14.9628 9.03722C15.5488 9.6232 15.9478 10.3698 16.1095 11.1826C16.2712 11.9954 16.1882 12.8378 15.8711 13.6034C15.5539 14.3691 15.0169 15.0235 14.3278 15.4839C13.6388 15.9443 12.8287 16.19 12 16.19C10.8896 16.1874 9.82535 15.7451 9.04014 14.9599C8.25493 14.1747 7.81264 13.1104 7.81 12Z"
        fill="currentColor"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ActionsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M16 10H13V7H11V10H8V12H11V15H13V12H16V10Z" fill="currentColor" />
      <path d="M15 22H9V24H15V22Z" fill="currentColor" />
      <path
        d="M14.6 21H9.40004L7.64004 17.69C6.51998 16.9706 5.59931 15.9806 4.9631 14.8113C4.32689 13.642 3.99567 12.3312 4.00004 11C4.00004 8.87827 4.8429 6.84344 6.34319 5.34315C7.84348 3.84285 9.87831 3 12 3C14.1218 3 16.1566 3.84285 17.6569 5.34315C19.1572 6.84344 20 8.87827 20 11C20.0044 12.3312 19.6732 13.642 19.037 14.8113C18.4008 15.9806 17.4801 16.9706 16.36 17.69L14.6 21ZM10.6 19H13.4L14.85 16.27L15.09 16.13C15.9758 15.5979 16.7091 14.846 17.2189 13.9472C17.7287 13.0485 17.9978 12.0333 18 11C18 9.4087 17.3679 7.88258 16.2427 6.75736C15.1175 5.63214 13.5913 5 12 5C10.4087 5 8.88262 5.63214 7.7574 6.75736C6.63218 7.88258 6.00004 9.4087 6.00004 11C6.00403 12.0316 6.27392 13.0447 6.78366 13.9416C7.2934 14.8384 8.02576 15.5887 8.91004 16.12L9.15004 16.26L10.6 19Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}

export function MenuIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 2H0V0H16V2Z" fill="#FAFAF9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 8H0V6H16V8Z" fill="#FAFAF9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 14H0V12H16V14Z" fill="#FAFAF9" />
    </svg>
  );
}

export function CloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.364 2.77817C14.7545 2.38765 14.7545 1.75448 14.364 1.36396C13.9734 0.973437 13.3403 0.973437 12.9497 1.36396L8 6.31371L3.05025 1.36396C2.65973 0.973437 2.02656 0.973437 1.63604 1.36396C1.24552 1.75448 1.24551 2.38765 1.63604 2.77817L6.58579 7.72792L1.63604 12.6777C1.24552 13.0682 1.24552 13.7014 1.63604 14.0919C2.02656 14.4824 2.65973 14.4824 3.05025 14.0919L8 9.14214L12.9497 14.0919C13.3403 14.4824 13.9734 14.4824 14.364 14.0919C14.7545 13.7014 14.7545 13.0682 14.364 12.6777L9.41421 7.72792L14.364 2.77817Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0H16V16H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function UserIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        d="M5.35535 22.247C5.79299 19.7058 7.46594 17.5881 9.73888 16.5364"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
      <path
        d="M16.2612 16.5364C17.0942 16.9246 17.8495 17.454 18.4911 18.0964C19.5993 19.2046 20.3688 20.6383 20.6448 22.247"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
      <path
        d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
      <path
        d="M13 17.9411C15.729 17.9411 17.9412 15.4128 17.9412 12.294C17.9412 9.17525 15.729 6.64697 13 6.64697C10.2711 6.64697 8.05884 9.17525 8.05884 12.294C8.05884 15.4128 10.2711 17.9411 13 17.9411Z"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function LogOutIcon() {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none">
      <path
        d="M1.875 1.2915L10.9832 4.93297C11.6476 5.19824 12.0833 5.84188 12.0833 6.55664V19.2493C12.0833 20.4491 10.9027 21.2927 9.76692 20.9045L2.18358 18.3118C1.47542 18.0699 1 17.4047 1 16.6567V2.74902C1 1.78298 1.784 1 2.75 1H14.4167C15.3827 1 16.1667 1.78298 16.1667 2.74902V16.1582C16.1667 17.1242 15.3827 17.9072 14.4167 17.9072H12.0833"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M9.97917 12.3687C9.97917 12.6557 9.74624 12.8889 9.45833 12.8889C9.17042 12.8889 8.9375 12.6557 8.9375 12.3687C8.9375 12.0816 9.17042 11.8484 9.45833 11.8484C9.74624 11.8484 9.97917 12.0816 9.97917 12.3687Z"
        fill="black"
        stroke="#FAFAF9"
      />
      <path
        d="M19.375 7.12158L22 9.74512L19.375 12.3687"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path d="M21.7084 9.74512H16.4584" stroke="#FAFAF9" strokeMiterlimit="10" />
    </svg>
  );
}

export function ReferralsIcon() {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none">
      <path
        d="M10.0003 4.65945L12.9897 1.8356C14.1695 0.721466 16.0819 0.721466 17.261 1.8356L18.1152 2.64225C19.2949 3.75639 19.2949 5.56251 18.1152 6.67665L13.4174 11.1132C12.2377 12.2274 10.3252 12.2274 9.14613 11.1132L8.29199 10.3066"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
      <path
        d="M10.0003 14.3409L7.01082 17.1647C5.8311 18.2783 3.91865 18.2783 2.73953 17.1647L1.88479 16.3581C0.705069 15.244 0.705069 13.4378 1.88479 12.3237L6.58255 7.88712C7.76227 6.77298 9.67471 6.77298 10.8538 7.88712L11.708 8.69377"
        stroke="#FAFAF9"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function DesktopNavbarLogo() {
  return (
    <svg width={155} height={35} viewBox="0 0 911 148">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M462.52 126.09V21.05h16.535v105.04z" />
        <path d="M462.52 126.09V21.05h16.535v105.04z" />
        <path d="M462.52 126.09V21.05h16.535v105.04zM531.811 48.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V117.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V97.27c-3.78-5.197-10.866-7.874-18.11-7.874z" />
        <path d="M531.811 48.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V117.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V97.27c-3.78-5.197-10.866-7.874-18.11-7.874z" />
        <path d="M531.811 48.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V117.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V97.27c-3.78-5.197-10.866-7.874-18.11-7.874zM636.22 126.09v-10.08c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V50.026h16.536V97.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V50.025h16.536v76.063H636.22z" />
        <path d="M636.22 126.09v-10.08c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V50.026h16.536V97.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V50.025h16.536v76.063H636.22z" />
        <path d="M636.22 126.09v-10.08c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V50.026h16.536V97.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V50.025h16.536v76.063H636.22z" />
        <g>
          <path d="M725.512 126.09V78.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V50.026h16.536V60.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
          <path d="M725.512 126.09V78.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V50.026h16.536V60.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
          <path d="M725.512 126.09V78.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V50.026h16.536V60.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
        </g>
        <g>
          <path d="M758.74 87.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
          <path d="M758.74 87.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
          <path d="M758.74 87.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
        </g>
        <g>
          <path d="M327.402 48.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
          <path d="M327.402 48.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
          <path d="M327.402 48.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
        </g>
        <g>
          <path d="M403.307 126.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
          <path d="M403.307 126.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
          <path d="M403.307 126.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
        </g>
        <g>
          <path d="M175.906 73.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89l-15.118 8.503c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V85.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054-30.394 0-55.118-21.574-55.118-54.488z" />
          <path d="M175.906 73.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89l-15.118 8.503c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V85.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054-30.394 0-55.118-21.574-55.118-54.488z" />
          <path d="M175.906 73.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89l-15.118 8.503c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V85.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054-30.394 0-55.118-21.574-55.118-54.488z" />
        </g>
        <g>
          <path d="M893.228 126.09V78.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V21.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
          <path d="M893.228 126.09V78.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V21.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
          <path d="M893.228 126.09V78.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V21.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
        </g>
        <path
          d="M38.838 62.568c-5.044 0-9.171 4.133-9.171 9.184v45.26c0 5.051 4.127 9.185 9.171 9.185s9.171-4.134 9.171-9.184v-45.26c0-5.052-4.127-9.185-9.171-9.185zM112.212 126.197h-.004c-5.044 0-9.171-4.134-9.171-9.184v-45.26c0-5.052 4.127-9.185 9.17-9.185h.005c5.044 0 9.167 4.133 9.167 9.184v45.26c0 5.051-4.123 9.185-9.167 9.185zM75.312 104.914h.004c5.044 0 9.171-4.133 9.171-9.184V50.47c0-5.051-4.127-9.185-9.171-9.185h-.004c-5.044 0-9.167 4.134-9.167 9.184v45.26c0 5.052 4.123 9.185 9.167 9.185zM84.694 138.223v-13.446c0-5.05-4.127-9.184-9.171-9.184-5.048 0-9.171 4.134-9.171 9.184v13.446c0 5.051 4.123 9.185 9.171 9.185 5.044 0 9.171-4.134 9.171-9.185z"
          stroke="#FFF"
          strokeWidth={0.656}
        />
        <g>
          <path d="M75.523.367a9.106 9.106 0 00-4.586 1.222L5.51 39.42C1.143 41.945-.37 47.587 2.153 51.965c2.522 4.373 8.16 5.887 12.528 3.361l60.842-35.175 60.842 35.175c4.367 2.526 10.006 1.012 12.528-3.361 2.522-4.378 1.01-10.02-3.357-12.546L80.11 1.59A9.106 9.106 0 0075.523.367z" />
        </g>
      </g>
    </svg>
  );
}

export function MobileNavbarLogo() {
  return (
    <svg width="100%" height={19} viewBox="0 0 736 112">
      <g fill="#FFF" fillRule="nonzero">
        <path d="M287.52 108.09V3.05h16.535v105.04z" />
        <path d="M287.52 108.09V3.05h16.535v105.04z" />
        <path d="M287.52 108.09V3.05h16.535v105.04zM356.811 30.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V99.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V79.27c-3.78-5.197-10.866-7.874-18.11-7.874z" />
        <path d="M356.811 30.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V99.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V79.27c-3.78-5.197-10.866-7.874-18.11-7.874z" />
        <path d="M356.811 30.136c16.85 0 31.654 7.087 31.654 27.56v50.393h-16.536V99.9c-5.67 6.457-14.488 10.079-24.567 10.079-12.44 0-26.456-8.346-26.456-25.197 0-17.48 14.015-24.724 26.456-24.724 10.236 0 19.055 3.307 24.567 9.764v-11.34c0-8.818-7.244-14.172-17.795-14.172-8.504 0-15.906 3.15-22.52 9.606l-6.771-11.496c8.818-8.347 19.842-12.284 31.968-12.284zm-2.992 41.26c-9.291 0-16.22 5.355-16.22 13.701 0 8.19 6.929 13.543 16.22 13.543 7.244 0 14.33-2.677 18.11-7.874V79.27c-3.78-5.197-10.866-7.874-18.11-7.874zM461.22 108.09V98.01c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V32.026h16.536V79.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V32.025h16.536v76.063H461.22z" />
        <path d="M461.22 108.09V98.01c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V32.026h16.536V79.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V32.025h16.536v76.063H461.22z" />
        <path d="M461.22 108.09V98.01c-5.511 6.142-14.96 11.969-26.929 11.969-16.378 0-24.41-8.504-24.41-24.094V32.026h16.536V79.9c0 11.811 5.985 15.433 15.276 15.433 8.346 0 15.59-4.724 19.527-9.92V32.025h16.536v76.063H461.22z" />
        <g>
          <path d="M550.512 108.09V60.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V32.026h16.536V42.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
          <path d="M550.512 108.09V60.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V32.026h16.536V42.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
          <path d="M550.512 108.09V60.53c0-11.81-5.984-15.748-15.276-15.748-8.504 0-15.748 5.04-19.527 10.236v53.071h-16.536V32.026h16.536V42.42c5.039-5.984 14.803-12.284 26.771-12.284 16.378 0 24.567 8.82 24.567 24.41v53.543h-16.535z" />
        </g>
        <g>
          <path d="M583.74 69.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
          <path d="M583.74 69.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
          <path d="M583.74 69.979c0-22.992 16.22-39.843 39.37-39.843 14.803 0 23.78 6.3 29.134 13.544l-10.866 10.079c-4.252-5.985-10.079-8.977-17.48-8.977-13.859 0-22.992 10.394-22.992 25.197 0 14.803 9.133 25.354 22.992 25.354 7.401 0 13.228-3.15 17.48-8.976l10.866 10.079c-5.354 7.244-14.33 13.543-29.134 13.543-23.15 0-39.37-16.85-39.37-40z" />
        </g>
        <g>
          <path d="M152.402 30.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
          <path d="M152.402 30.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
          <path d="M152.402 30.136c24.409 0 39.212 18.11 39.212 39.843 0 21.89-14.803 40-39.212 40-24.095 0-39.056-18.11-39.056-40 0-21.732 14.961-39.843 39.056-39.843zm0 14.646c-14.016 0-21.89 11.811-21.89 25.197 0 13.543 7.874 25.354 21.89 25.354 14.173 0 22.047-11.81 22.047-25.354 0-13.386-7.874-25.197-22.047-25.197z" />
        </g>
        <g>
          <path d="M228.307 108.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
          <path d="M228.307 108.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
          <path d="M228.307 108.09l-31.024-76.064h17.638l22.205 57.008 22.205-57.008h17.795l-31.024 76.063z" />
        </g>
        <g>
          <path d="M.906 55.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89L83.268 31.71c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V67.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054C25.63 110.136.906 88.562.906 55.648z" />
          <path d="M.906 55.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89L83.268 31.71c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V67.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054C25.63 110.136.906 88.562.906 55.648z" />
          <path d="M.906 55.648c0-32.913 24.724-54.33 55.118-54.33 20.472 0 33.858 9.763 42.362 21.89L83.268 31.71c-5.512-7.874-15.433-14.015-27.244-14.015-20.788 0-36.22 15.905-36.22 37.952 0 22.048 15.432 38.11 36.22 38.11 10.708 0 20.157-5.039 24.882-9.448V67.932h-31.34v-16.22h49.765v39.37c-10.394 11.495-25.04 19.054-43.307 19.054C25.63 110.136.906 88.562.906 55.648z" />
        </g>
        <g>
          <path d="M718.228 108.09V60.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V3.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
          <path d="M718.228 108.09V60.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V3.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
          <path d="M718.228 108.09V60.214c0-11.81-6.141-15.433-15.433-15.433-8.346 0-15.59 5.04-19.527 10.236v53.071h-16.536V3.05h16.536v39.37c5.04-5.983 14.96-12.283 26.929-12.283 16.378 0 24.567 8.504 24.567 24.095v53.858h-16.536z" />
        </g>
      </g>
    </svg>
  );
}
