import {
  AccessRequestAccepted,
  AccessRequestDeclined,
  AccountUnverified,
  AccountVerified,
  AddedAsCollaborator,
  JoinedAsCollaboratorOnProject,
  JoinedAsCollaboratorOnStory,
  JoinedAsGovernmentMember,
  JoinedAsVendorMember,
  ProductApproved,
  ProductComment,
  ProductCommentMention,
  ProductCommentReply,
  ProductCommentUpvote,
  ProductRecommendation,
  ProductRecommendationUpvote,
  ProductUpvote,
  ProjectComment,
  ProjectCommentReply,
  ProjectCommentUpvote,
  QuestionComment,
  QuestionCommentMention,
  QuestionCommentReply,
  QuestionCommentUpvote,
  RecommendationEndorsementComment,
  RecommendationEndorsementCommentMention,
  RecommendationEndorsementCommentReply,
  RecommendationEndorsementCommentUpvote,
  RecommendationEndorsementUpvote,
  ResourceComment,
  ResourceCommentMention,
  ResourceCommentReply,
  ResourceCommentUpvote,
  ResourceUpvote,
  StoryComment,
  StoryCommentMention,
  StoryCommentReply,
  StoryCommentUpvote,
  StoryUpvote,
  StoryWasEdited,
  AddedAsAuthor,
  GovernmentAcceptedGroupRequest,
  GovernmentVerifiedProject,
  GovernmentVerifiedVendorProject,
  VendorAcceptedGroupRequest,
  VendorVerifiedProject,
  PostUpvote,
  GovernmentAcceptedProductInvitation,
  GovernmentDeniedProductInvitation,
  WelcomeToGroup,
} from "~/src/components/notifications";
import pick from "lodash/fp/pick";
import React, { ReactElement } from "react";
import getGovlaunchBaseUrl from "~/lib/utils/getGovlaunchBaseUrl";

const COMMENT_ON_RESOURCE = "commentOnResource";
const COMMENT_ON_STORY = "commentOnStory";
const COMMENT_ON_PRODUCT = "commentOnProduct";
const COMMENT_ON_QUESTION = "commentOnQuestion";
const COMMENT_ON_RECOMMENDATION_ENDORSEMENT = "commentOnRecommendationEndorsement";
const REPLY_ON_RESOURCE_COMMENT = "replyOnResourceComment";
const REPLY_ON_STORY_COMMENT = "replyOnStoryComment";
const REPLY_ON_PRODUCT_COMMENT = "replyOnProductComment";
const REPLY_ON_QUESTION_COMMENT = "replyOnQuestionComment";
const REPLY_ON_RECOMMENDATION_ENDORSEMENT_COMMENT = "replyOnRecommendationEndorsementComment";
const MENTION_ON_STORY_COMMENT = "mentionOnStoryComment";
const MENTION_ON_RESOURCE_COMMENT = "mentionOnResourceComment";
const MENTION_ON_PRODUCT_COMMENT = "mentionOnProductComment";
const MENTION_ON_QUESTION_COMMENT = "mentionOnQuestionComment";
const MENTION_ON_RECOMMENDATION_ENDORSEMENT_COMMENT = "mentionOnRecommendationEndorsementComment";
const UPVOTE_ON_RESOURCE_COMMENT = "upvoteOnResourceComment";
const UPVOTE_ON_STORY_COMMENT = "upvoteOnStoryComment";
const UPVOTE_ON_PRODUCT_COMMENT = "upvoteOnProductComment";
const UPVOTE_ON_QUESTION_COMMENT = "upvoteOnQuestionComment";
const UPVOTE_ON_RECOMMENDATION_ENDORSEMENT_COMMENT = "upvoteOnRecommendationEndorsementComment";
const UPVOTE_ON_RESOURCE = "upvoteOnResource";
const UPVOTE_ON_STORY = "upvoteOnStory";
const UPVOTE_ON_POST = "upvoteOnPost";
const UPVOTE_ON_PRODUCT = "upvoteOnProduct";
const UPVOTE_ON_RECOMMENDATION_ENDORSEMENT = "upvoteOnRecommendationEndorsement";
const UPVOTE_ON_PRODUCT_RECOMMENDATION = "upvoteOnProductRecommendation";
const PRODUCT_RECOMMENDATION = "productRecommendation";
const STORY_WAS_EDITED = "storyWasEdited";
const ADDED_AS_COLLABORATOR = "addedAsCollaborator";
const ADDED_AS_AUTHOR = "addedAsAuthor";
const ACCEPTED_ACCESS_REQUEST = "acceptedAccessRequest";
const DECLINED_ACCESS_REQUEST = "declinedAccessRequest";
const VERIFIED_ACCOUNT = "verifiedAccount";
const UNVERIFIED_ACCOUNT = "unverifiedAccount";
const PRODUCT_APPROVED = "productWasApproved";

const WELCOME_TO_GROUP = "welcomeToGroup";

const COMMENT_ON_PROJECT = "commentOnProject";
const REPLY_ON_PROJECT_COMMENT = "replyOnProjectComment";
const UPVOTE_ON_PROJECT_COMMENT = "upvoteOnProjectComment";
const JOINED_AS_COLLABORATOR_ON_STORY = "joinedAsCollaboratorOnStory";
const JOINED_AS_COLLABORATOR_ON_PROJECT = "joinedAsCollaboratorOnProject";
const JOINED_AS_GOVERNMENT_MEMBER = "joinedAsGovernmentMember";
const JOINED_AS_VENDOR_MEMBER = "joinedAsVendorMember";

const GOVERNMENT_ACCEPTED_GROUP_REQUEST = "governmentAcceptedGroupRequest";
const GOVERNMENT_VERIFIED_PROJECT = "governmentVerifiedProject";
const GOVERNMENT_VERIFIED_VENDOR_PROJECT = "governmentVerifiedVendorProject";

const VENDOR_ACCEPTED_GROUP_REQUEST = "vendorAcceptedGroupRequest";
const VENDOR_VERIFIED_PROJECT = "vendorVerifiedProject";

const GOVERNMENT_ACCEPTED_PRODUCT_INVITATION = "governmentAcceptedProductInvitation";
const GOVERNMENT_DENIED_PRODUCT_INVITATION = "governmentDeniedProductInvitation";

function linkToInnovatorShare(notification: any): string {
  return `${getGovlaunchBaseUrl("innovators")}/share/${notification.payload.resource.slug}`;
}

function linkToStory(notification: any): string {
  return `/stories/${notification.payload.story.slug}`;
}

function linkToPost(notification: any): string {
  return `/posts/${notification.payload.post._id}`;
}

function linkToProduct(notification: any): string {
  return `/products/${notification.payload.product.slug}`;
}

function linkToQuestion(notification: any): string {
  return `${getGovlaunchBaseUrl("innovators")}/ask/${notification.payload.question.slug}`;
}

function linkToRecommendationEndorsement(notification: any): string {
  const { question, recommendationEndorsement } = notification.payload;
  return `${getGovlaunchBaseUrl("innovators")}/ask/${question.slug}/endorsements/${recommendationEndorsement._id}`;
}

function linkToProductRecommendation(notification: any): string {
  const { question, productRecommendation } = notification.payload;
  return `${getGovlaunchBaseUrl("innovators")}/ask/${question.slug}/recommendations/${productRecommendation._id}`;
}

const NotificationMap = {
  [COMMENT_ON_RESOURCE]: {
    component: ResourceComment,
    url: linkToInnovatorShare,
  },
  [UPVOTE_ON_RESOURCE_COMMENT]: {
    component: ResourceCommentUpvote,
    url: linkToInnovatorShare,
  },
  [REPLY_ON_RESOURCE_COMMENT]: {
    component: ResourceCommentReply,
    url: linkToInnovatorShare,
  },
  [MENTION_ON_RESOURCE_COMMENT]: {
    component: ResourceCommentMention,
    url: linkToInnovatorShare,
  },
  [UPVOTE_ON_RESOURCE]: {
    component: ResourceUpvote,
    url: linkToInnovatorShare,
  },
  [MENTION_ON_STORY_COMMENT]: {
    component: StoryCommentMention,
    url: linkToStory,
  },
  [COMMENT_ON_STORY]: {
    component: StoryComment,
    url: linkToStory,
  },
  [COMMENT_ON_PRODUCT]: {
    component: ProductComment,
    url: linkToProduct,
  },
  [REPLY_ON_STORY_COMMENT]: {
    component: StoryCommentReply,
    url: linkToStory,
  },
  [REPLY_ON_PRODUCT_COMMENT]: {
    component: ProductCommentReply,
    url: linkToProduct,
  },
  [MENTION_ON_PRODUCT_COMMENT]: {
    component: ProductCommentMention,
    url: linkToProduct,
  },
  [UPVOTE_ON_STORY_COMMENT]: {
    component: StoryCommentUpvote,
    url: linkToStory,
  },
  [UPVOTE_ON_PRODUCT_COMMENT]: {
    component: ProductCommentUpvote,
    url: linkToProduct,
  },
  [UPVOTE_ON_STORY]: {
    component: StoryUpvote,
    url: linkToStory,
  },
  [UPVOTE_ON_PRODUCT]: {
    component: ProductUpvote,
    url: linkToProduct,
  },
  [UPVOTE_ON_POST]: {
    component: PostUpvote,
    url: linkToPost,
  },
  [COMMENT_ON_QUESTION]: {
    component: QuestionComment,
    url: linkToQuestion,
  },
  [REPLY_ON_QUESTION_COMMENT]: {
    component: QuestionCommentReply,
    url: linkToQuestion,
  },
  [MENTION_ON_QUESTION_COMMENT]: {
    component: QuestionCommentMention,
    url: linkToQuestion,
  },
  [UPVOTE_ON_QUESTION_COMMENT]: {
    component: QuestionCommentUpvote,
    url: linkToQuestion,
  },
  [COMMENT_ON_RECOMMENDATION_ENDORSEMENT]: {
    component: RecommendationEndorsementComment,
    url: linkToRecommendationEndorsement,
  },
  [REPLY_ON_RECOMMENDATION_ENDORSEMENT_COMMENT]: {
    component: RecommendationEndorsementCommentReply,
    url: linkToRecommendationEndorsement,
  },
  [MENTION_ON_RECOMMENDATION_ENDORSEMENT_COMMENT]: {
    component: RecommendationEndorsementCommentMention,
    url: linkToRecommendationEndorsement,
  },
  [UPVOTE_ON_RECOMMENDATION_ENDORSEMENT_COMMENT]: {
    component: RecommendationEndorsementCommentUpvote,
    url: linkToRecommendationEndorsement,
  },
  [UPVOTE_ON_RECOMMENDATION_ENDORSEMENT]: {
    component: RecommendationEndorsementUpvote,
    url: linkToRecommendationEndorsement,
  },
  [UPVOTE_ON_PRODUCT_RECOMMENDATION]: {
    component: ProductRecommendationUpvote,
    url: linkToProductRecommendation,
  },
  [PRODUCT_RECOMMENDATION]: {
    component: ProductRecommendation,
    url: linkToProductRecommendation,
  },
  [STORY_WAS_EDITED]: {
    component: StoryWasEdited,
    url: linkToStory,
  },
  [ADDED_AS_COLLABORATOR]: {
    component: AddedAsCollaborator,
    url: linkToStory,
  },
  [ADDED_AS_AUTHOR]: {
    component: AddedAsAuthor,
    url: linkToStory,
  },
  [ACCEPTED_ACCESS_REQUEST]: {
    component: AccessRequestAccepted,
    url: ({ payload }: any) => {
      if (payload.government) {
        return `${getGovlaunchBaseUrl("portal")}/governments/${payload.government.slug}`;
      }

      if (payload.group) {
        return `${getGovlaunchBaseUrl("portal")}/groups/${payload.group.slug}`;
      }

      return `${getGovlaunchBaseUrl("portal")}/vendors/${payload.company.slug}`;
    },
  },
  [WELCOME_TO_GROUP]: {
    component: WelcomeToGroup,
    url: ({ payload }: any) => {
      return `${getGovlaunchBaseUrl("portal")}/groups/${payload.slug}`;
    },
  },
  [DECLINED_ACCESS_REQUEST]: {
    component: AccessRequestDeclined,
    url: ({ payload }: any) => `${getGovlaunchBaseUrl("portal")}/governments/${payload.government.slug}`,
  },
  [VERIFIED_ACCOUNT]: {
    component: AccountVerified,
    url: () => getGovlaunchBaseUrl("portal"),
  },
  [UNVERIFIED_ACCOUNT]: {
    component: AccountUnverified,
    url: () => getGovlaunchBaseUrl("portal"),
  },
  [PRODUCT_APPROVED]: {
    component: ProductApproved,
    url: ({ payload }: any) => `/products/${payload.product.slug}`,
  },
  [COMMENT_ON_PROJECT]: {
    component: ProjectComment,
    url: projectUrl,
  },
  [REPLY_ON_PROJECT_COMMENT]: {
    component: ProjectCommentReply,
    url: projectUrl,
  },
  [UPVOTE_ON_PROJECT_COMMENT]: {
    component: ProjectCommentUpvote,
    url: projectUrl,
  },
  [JOINED_AS_COLLABORATOR_ON_PROJECT]: {
    component: JoinedAsCollaboratorOnProject,
    url: ({ payload }: any) => {
      if (payload.project.government) {
        return `/governments/${payload.project.government.slug}/projects/${payload.project.slug}`;
      }

      if (payload.project.group) {
        return `/groups/${payload.project.group.slug}/projects/${payload.project.slug}`;
      }

      throw new Error("Unable to generate Project URL");
    },
  },
  [JOINED_AS_COLLABORATOR_ON_STORY]: {
    component: JoinedAsCollaboratorOnStory,
    url: ({ payload }: any) => `/stories/${payload.story.slug}`,
  },
  [JOINED_AS_GOVERNMENT_MEMBER]: {
    component: JoinedAsGovernmentMember,
    url: ({ payload }: any) => `/governments/${payload.government.slug}`,
  },
  [JOINED_AS_VENDOR_MEMBER]: {
    component: JoinedAsVendorMember,
    url: ({ payload }: any) => `/vendors/${payload.vendor.slug}`,
  },
  [GOVERNMENT_ACCEPTED_GROUP_REQUEST]: {
    component: GovernmentAcceptedGroupRequest,
    url: ({ payload }: any) => `/groups/${payload.group.slug}`,
  },
  [GOVERNMENT_VERIFIED_PROJECT]: {
    component: GovernmentVerifiedProject,
    url: projectUrl,
  },
  [GOVERNMENT_VERIFIED_VENDOR_PROJECT]: {
    component: GovernmentVerifiedVendorProject,
    url: projectUrl,
  },
  [VENDOR_ACCEPTED_GROUP_REQUEST]: {
    component: VendorAcceptedGroupRequest,
    url: ({ payload }: any) => `/groups/${payload.group.slug}`,
  },
  [VENDOR_VERIFIED_PROJECT]: {
    component: VendorVerifiedProject,
    url: projectUrl,
  },
  [GOVERNMENT_ACCEPTED_PRODUCT_INVITATION]: {
    component: GovernmentAcceptedProductInvitation,
    url: productUrl,
  },
  [GOVERNMENT_DENIED_PRODUCT_INVITATION]: {
    component: GovernmentDeniedProductInvitation,
    url: productUrl,
  },
};

interface INotificationProps {
  notification: any;
  group: string;
  onRead: ({ notificationUrl }: { notificationUrl: string }) => any;
  [key: string]: any;
}

export default function Notification({
  notification,
  group,
  onRead,
  ...props
}: INotificationProps): ReactElement | null {
  const {
    component: Component,
    url,
  }: {
    component: any;
    url: (notification: any) => string;
  } = pick(["component", "url"], NotificationMap[notification.type as keyof typeof NotificationMap]);

  if (!Component) {
    return null;
  }

  if (typeof url === "function") {
    const notificationUrl = url(notification);

    return (
      <Component
        notification={notification}
        group={group}
        tag="a"
        href={notificationUrl}
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault();

          onRead({
            notificationUrl,
          });
        }}
        {...props}
      />
    );
  }

  return null;
}

function productUrl({ payload }: any) {
  return `/products/${payload.product.slug}`;
}

function projectUrl({ payload }: any) {
  return `/projects/${payload.project.slug}`;
}
