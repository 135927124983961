import { AlgoliaFetch } from "@govlaunch/algolia";
import * as palette from "@govlaunch/palette";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import get from "lodash/fp/get";
import { useRouter } from "next/router";
import React, { createRef, forwardRef, HTMLAttributes, ReactNode, RefObject, useState } from "react";
import { createPortal } from "react-dom";
import { useIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelfie } from "~/components/auth/Auth";
import HorizontalLine from "~/components/HorizontalLine";
import SearchContext from "~/components/search/SearchContext";
import GovernmentResultItem from "~/components/searchbar/results/GovernmentResultItem";
import ProductResultItem from "~/components/searchbar/results/ProductResultItem";
import ProjectResultItem from "~/components/searchbar/results/ProjectResultItem";
import StoryResultItem from "~/components/searchbar/results/StoryResultItem";
import SearchInput from "~/components/searchbar/SearchInput";

interface IMenuProps {
  children: ReactNode;
  innerRef: RefObject<HTMLDivElement>;
}

interface ISearchbarProps {
  initialIsOpen?: boolean;
  onClose?: () => any;
}

function Searchbar({ initialIsOpen = false, onClose }: ISearchbarProps) {
  const router = useRouter();
  const initialQuery = get("query.query", router) || "";
  const [results, setResults] = useState<any>({
    governments: [],
    products: [],
    projects: [],
    stories: [],
  });
  const menuRef = createRef<HTMLDivElement>();
  const user = useSelfie();
  const intl = useIntl();

  return (
    <Downshift
      id="searchbar"
      initialIsOpen={initialIsOpen}
      initialInputValue={initialQuery}
      itemToString={() => ""}
      stateReducer={defaultStateReducer}
      onStateChange={(changes) => {
        if (changes.isOpen === false) {
          if (onClose) {
            onClose();
          }
        }
      }}
      onChange={(item) => {
        if (!item) {
          return;
        }

        if (/signin/i.test(router.route) || /signup/i.test(router.route)) {
          return;
        }

        if (item.__typename === "Story") {
          router.push("/stories/[storySlug]", `/stories/${item.slug}`);
        }

        if (item.__typename === "Product") {
          router.push("/products/[productSlug]", `/products/${item.slug}`);
        }

        if (item.__typename === "Government") {
          router.push("/governments/[governmentSlug]", `/governments/${item.slug}`);
        }

        if (item.__typename === "Project") {
          router.push(
            "/governments/[governmentSlug]/projects/[projectSlug]",
            `/governments/${item.government.slug}/projects/${item.slug}`,
          );
        }
      }}
    >
      {({
        getInputProps,
        getRootProps,
        getMenuProps,
        inputValue: searchQuery,
        getItemProps,
        closeMenu,
        isOpen: isFocused,
        highlightedIndex,
        openMenu,
      }) => (
        <SearchContainer
          isFocused={isFocused}
          {...getRootProps({
            role: null,
            "aria-labelledby": null,
          } as any)}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SearchContext.Provider value={searchQuery}>
            <OutsideClickHandler
              onOutsideClick={(e) => {
                if (menuRef.current && !menuRef.current.contains(e.target as any)) {
                  closeMenu();
                }
              }}
            >
              <div
                css={{
                  height: 81,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SearchInput
                  {...(getInputProps({
                    "aria-label": intl.formatMessage({
                      defaultMessage: "navbar search input",
                      id: "ZoaTU9",
                    }),
                    "aria-labelledby": null,
                  }) as any)}
                  onFocus={() => {
                    openMenu();
                  }}
                  isFocused={isFocused}
                  value={searchQuery || ""}
                  onClose={() => {
                    closeMenu();
                  }}
                  autoFocus={initialIsOpen}
                />
              </div>
            </OutsideClickHandler>

            <AlgoliaFetch
              query={searchQuery || " "}
              indexes={["governments", "projects", "stories", "products"]}
              params={{
                governments: {
                  hitsPerPage: 5,
                },
                projects: {
                  hitsPerPage: 5,
                },
                stories: {
                  hitsPerPage: 5,
                },
                products: {
                  hitsPerPage: 5,
                },
              }}
              onStateChange={(response: any) => {
                setResults(
                  filterAlgoliaResults({
                    ...results,
                    ...response.results,
                  }),
                );
              }}
            />

            {isFocused && (
              <Menu
                innerRef={menuRef}
                {...getMenuProps({
                  refKey: "innerRef",
                  "aria-label": intl.formatMessage({
                    defaultMessage: "searchbar menu items",
                    id: "z8KDD8",
                  }),
                })}
              >
                <HorizontalLine color={palette.lightestGray} />
                <div>
                  {mapTypenames(results).map((result, index) => {
                    if (
                      result.__typename === "GovernmentProject" ||
                      result.__typename === "GroupProject" ||
                      result.__typename === "VendorProject"
                    ) {
                      return (
                        <ProjectResultItem
                          project={result}
                          key={result._id}
                          {...getItemProps({
                            item: result,
                            onClick: (e) => {
                              if (!user) {
                                e.preventDefault();
                              }
                            },
                          })}
                          highlighted={highlightedIndex === index}
                        />
                      );
                    }

                    if (result.__typename === "Story") {
                      return (
                        <StoryResultItem
                          story={result}
                          key={result._id}
                          {...getItemProps({
                            item: result,
                            onClick: (e) => {
                              if (!user) {
                                e.preventDefault();
                              }
                            },
                          })}
                          highlighted={highlightedIndex === index}
                        />
                      );
                    }

                    if (
                      result.__typename === "Product" &&
                      (result.computed.tier > 1 || (searchQuery || "").toLowerCase() === result.name.toLowerCase())
                    ) {
                      return (
                        <ProductResultItem
                          product={result}
                          key={result._id}
                          {...getItemProps({
                            item: result,
                            onClick: (e) => {
                              if (!user) {
                                e.preventDefault();
                              }
                            },
                          })}
                          highlighted={highlightedIndex === index}
                        />
                      );
                    }

                    if (result.__typename === "Government") {
                      return (
                        <GovernmentResultItem
                          government={result}
                          key={result._id}
                          {...getItemProps({
                            item: result,
                            onClick: (e) => {
                              if (!user) {
                                e.preventDefault();
                              }
                            },
                          })}
                          highlighted={highlightedIndex === index}
                        />
                      );
                    }

                    return null;
                  })}
                </div>
              </Menu>
            )}

            {isFocused &&
              createPortal(
                <div
                  css={{
                    position: "fixed",
                    top: 0,
                    bottom: "0%",
                    left: 0,
                    right: 0,
                    background: "rgba(90, 102, 119, 0.6)",
                    zIndex: 302,
                  }}
                />,
                document.body,
              )}
          </SearchContext.Provider>
        </SearchContainer>
      )}
    </Downshift>
  );
}

interface ISearchContainerProps {
  isFocused: boolean;
  children: ReactNode;
  innerRef: any;
}

const SearchContainer = forwardRef<any, ISearchContainerProps & HTMLAttributes<HTMLFormElement>>(
  ({ isFocused, children, ...props }, ref) => {
    if (isFocused) {
      return (
        <form
          {...props}
          ref={ref}
          style={{
            position: "absolute",
            left: 0,
            top: -4,
            width: "100%",
            height: 81,
            zIndex: 303,
          }}
        >
          {children}
        </form>
      );
    }

    return (
      <form
        {...props}
        ref={ref}
        style={{
          position: "relative",
        }}
      >
        {children}
      </form>
    );
  },
);

SearchContainer.displayName = "SearchContainer";

function Menu({ children, innerRef, ...props }: IMenuProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      ref={innerRef}
      css={{
        position: "absolute",
        top: 61,
        left: 0,
        width: "100%",
        background: "#fff",
        border: `1px solid #2F3844`,
        borderTop: 0,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
      }}
    >
      {children}
    </div>
  );
}

function filterAlgoliaResults(result: any): any {
  const governmentsLength = result.governments.length;
  const storiesLength = result.stories.length;
  const projectsLength = result.projects.length;
  const productsLength = result.products.length;

  const totalLength = governmentsLength + storiesLength + projectsLength + productsLength;

  if (totalLength <= 5) {
    return result;
  }

  if (productsLength > 1) {
    return filterAlgoliaResults({
      ...result,
      products: result.products.slice(0, productsLength - 1),
    });
  }

  if (storiesLength > 1) {
    return filterAlgoliaResults({
      ...result,
      stories: result.stories.slice(0, storiesLength - 1),
    });
  }

  if (projectsLength > 1) {
    return filterAlgoliaResults({
      ...result,
      projects: result.projects.slice(0, projectsLength - 1),
    });
  }

  if (governmentsLength > 1) {
    return filterAlgoliaResults({
      ...result,
      governments: result.governments.slice(0, governmentsLength - 1),
    });
  }

  return result;
}

function mapTypenames(results: any) {
  return [
    ...results.governments.map((government: any) => ({ __typename: "Government", ...government })),
    ...results.projects.map((project: any) => ({ __typename: getProjectTypename(project), ...project })),
    ...results.stories.map((story: any) => ({ __typename: "Story", ...story })),
    ...results.products.map((product: any) => ({ __typename: "Product", ...product })),
  ];
}

function getProjectTypename(project: any) {
  if (project.government) {
    return "GovernmentProject";
  }

  if (project.vendor) {
    return "VendorProject";
  }

  if (project.group) {
    return "GroupProject";
  }

  throw new Error("Unable to get project typename");
}

function defaultStateReducer(_: DownshiftState<any>, changes: StateChangeOptions<any>): StateChangeOptions<any> {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem: {
      return {
        ...changes,
        isOpen: false,
        inputValue: "",
      };
    }
    default:
      return changes;
  }
}

export default Searchbar;
