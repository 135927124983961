import React, { ReactElement } from "react";

interface INotificationSectionProps {
  children: ReactElement;
}

export default function NotificationSection({ children, ...props }: INotificationSectionProps) {
  return (
    <div
      css={{
        fontWeight: 500,
        fontSize: 14,
        padding: "8px 24px",
        backgroundColor: "#E3E6EB",
        borderBottom: `1px solid #ADB6C3`,
        color: "#3F4A5A",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
      {...props}
    >
      {children}
    </div>
  );
}
