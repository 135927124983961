import styled from "@emotion/styled";
import React from "react";

const Icon = styled.span`
  display: inline-flex;
  width: 32px;
  min-width: 32px;
`;

export default Icon;

export function UserIcon() {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none">
      <path
        d="M20.9 13.9C20.5 11.5 18.6 9.7 16.3 9.1C16.8 8.1 17 7 17 6C17 2.7 14.3 0 11 0C7.7 0 5 2.7 5 6C5 7 5.2 8.1 5.7 9.2C3.4 9.7 1.6 11.6 1.1 14L0 20H22L20.9 13.9ZM11 2C13.2 2 15 3.8 15 6.1C15 9 12.6 12.2 11 12.2C9.3 12.2 6.9 9 6.9 6.1C6.9 3.8 8.8 2 11 2ZM2.4 18L3.1 14.3C3.4 12.5 4.9 11.2 6.7 11.1C7.8 12.8 9.3 14.1 11.1 14.1C12.9 14.1 14.4 12.8 15.5 11.1C17.3 11.3 18.7 12.6 19.1 14.3L19.8 18H2.4Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}

export function CogIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M21 13V11H17.91C17.766 10.1121 17.4243 9.26797 16.91 8.53L19.09 6.35L17.66 4.93L15.47 7.11C14.732 6.59574 13.8879 6.25398 13 6.11V3H11V6.09C10.1121 6.23398 9.26797 6.57574 8.53 7.09L6.34 4.93L4.93 6.34L7.11 8.53C6.59574 9.26797 6.25398 10.1121 6.11 11H3V13H6.09C6.23398 13.8879 6.57574 14.732 7.09 15.47L4.93 17.66L6.34 19.07L8.52 16.89C9.25797 17.4043 10.1021 17.746 10.99 17.89V21H12.99V17.91C13.8779 17.766 14.722 17.4243 15.46 16.91L17.64 19.09L19.05 17.68L16.87 15.5C17.3843 14.762 17.726 13.9179 17.87 13.03H21V13ZM7.81 12C7.81 11.1713 8.05574 10.3612 8.51614 9.67216C8.97654 8.98312 9.63093 8.44608 10.3966 8.12894C11.1622 7.81181 12.0046 7.72884 12.8174 7.89051C13.6302 8.05218 14.3768 8.45124 14.9628 9.03722C15.5488 9.6232 15.9478 10.3698 16.1095 11.1826C16.2712 11.9954 16.1882 12.8378 15.8711 13.6034C15.5539 14.3691 15.0169 15.0235 14.3278 15.4839C13.6388 15.9443 12.8287 16.19 12 16.19C10.8896 16.1874 9.82535 15.7451 9.04014 14.9599C8.25493 14.1747 7.81264 13.1104 7.81 12Z"
        fill="#ADB6C3"
      />
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}

export function LogOutIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10 8.70992L11.8 10.5099L12.97 10.9999H3V12.9999H12.95L11.76 13.5299L9.99 15.2899L11.34 16.5699L15.91 11.9999L11.33 7.41992L10 8.70992Z"
        fill="#ADB6C3"
      />
      <path d="M9 3H7V5V7H9V5H18V19H9V17H7V19V21H9H20V3H9Z" fill="#ADB6C3" />
    </svg>
  );
}

export function ReferralsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17.6569 7.75796L16.2427 6.34375L6.34318 16.2432L7.75739 17.6575L17.6569 7.75796Z" fill="#ADB6C3" />
      <path
        d="M16.24 14.8299L14.12 12.7099L15.54 11.2899L16.24 11.9999L20.48 7.75994L16.24 3.51994L12 7.75994L12.71 8.45994L11.29 9.87994L9.17004 7.75994L16.24 0.689941L23.31 7.75994L16.24 14.8299Z"
        fill="#ADB6C3"
      />
      <path
        d="M7.75994 23.3099L0.689941 16.2399L7.75994 9.16992L9.87994 11.2899L8.45994 12.7099L7.75994 11.9999L3.51994 16.2399L7.75994 20.4799L11.9999 16.2399L11.2899 15.5399L12.7099 14.1199L14.8299 16.2399L7.75994 23.3099Z"
        fill="#ADB6C3"
      />
    </svg>
  );
}
