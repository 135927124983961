import { Mutation } from "@apollo/client/react/components";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import DismissOnboarding from "~/lib/mutations/DismissOnboarding";
import {
  IDismissOnboardingMutation,
  IDismissOnboardingMutationVariables,
} from "~/lib/mutations/__generated__/DismissOnboarding.generated";
import SelfieQuery from "~/lib/queries/SelfieQuery";
import { useSelfie } from "~/components/auth/Auth";

function CompleteProfileBanner() {
  const viewer = useSelfie();

  if (!viewer) {
    return null;
  }

  return (
    <Mutation<IDismissOnboardingMutation, IDismissOnboardingMutationVariables>
      mutation={DismissOnboarding}
      update={(proxy) => {
        proxy.writeQuery({
          query: SelfieQuery,
          data: {
            __typename: "Query",
            selfie: {
              ...viewer,
              showOnboardingBanner: false,
            },
          },
        });
      }}
    >
      {(dismissActionCenter) => (
        <div
          css={{
            position: "relative",
            padding: "24px 32px",
            border: `solid 1px #602400`,
            backgroundColor: "rgba(254, 143, 81, 0.5)",
            color: "#602400",
            borderRadius: 5,
            width: "100%",
          }}
        >
          <FormattedMessage defaultMessage="Almost there! We're missing some key information in your profile." id="S5qUM/" />{" "}
          <Link passHref={true} href="/[userSlug]/[[...tab]]?tab=settings" as={`/@${viewer.slug}/settings`}>
            <a
              css={{
                color: "#602400",
                fontWeight: 600,
                ":hover": {
                  textDecoration: "underline !important",
                },
              }}
            >
              <FormattedMessage defaultMessage="Add it now" id="Nq9h7m" />→
            </a>
          </Link>
          <span
            role="button"
            onClick={() => dismissActionCenter()}
            css={{
              position: "absolute",
              top: 12,
              right: 12,
              textTransform: "uppercase",
              fontSize: 12,
              letterSpacing: 0.5,
              color: "#602400",
              cursor: "pointer",
              ":hover": {
                textDecoration: "underline !important",
              },
            }}
          >
            <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
          </span>
        </div>
      )}
    </Mutation>
  );
}

export default CompleteProfileBanner;
