import fetch from "isomorphic-unfetch";
import { has, noop, pick, set, update } from "lodash/fp";
import queryString from "qs";
import { Component, ReactElement } from "react";
import getNotificationsApiUrl from "~/src/components/notifications/getNotificationsApiUrl";
import pubnubClient from "~/src/infra/pubnubClient";

export interface NotificationStreamChildrenProps {
  unreadCount: number;
  notifications: any[];
  groups: Record<string, any[]>;
  read: (notification: Notification) => void;
  readAll: () => void;
  hasMore: boolean;
  loading: boolean;
  loadMore: (beforeThisId: string) => Promise<void>;
}

interface NotificationStreamState {
  loading: boolean;
  stream: {
    notifications: any[];
    groups: Record<string, any[]>;
    unreadCount: number;
    hasMore: boolean;
  };
}

interface NotificationStreamProps {
  channels: string[];
  token: string | null;
  children: (props: NotificationStreamChildrenProps) => ReactElement;
}

type NotificationMessage = {
  type: "com.govlaunch.notifications:read" | "com.govlaunch.notifications:push";
  notification: any;
  group: string;
};

type Notification = {
  _id: string;
  priority: number;
  createdAt: string;
  readAt?: string | null;
  completedAt?: string | null;
  deletedAt?: string | null;
};

export default class NotificationStream extends Component<NotificationStreamProps, NotificationStreamState> {
  state = {
    loading: false,
    stream: {
      notifications: [],
      groups: {},
      unreadCount: 0,
      hasMore: false,
    },
  };

  componentDidMount() {
    const { channels } = this.props;

    pubnubClient.addListener({
      message: this.handleIncomingMessage,
    });

    this.fetchStream(null).then(() => {
      pubnubClient.subscribe({
        channels: channels.filter(Boolean),
        withPresence: false,
      });
    });
  }

  componentWillUnmount() {
    const { channels } = this.props;

    pubnubClient.removeListener({
      message: this.handleIncomingMessage,
    });

    pubnubClient.unsubscribe({
      channels: channels.filter(Boolean),
    });
  }

  fetchStream = (beforeThisId: string | null) => {
    this.setState({
      loading: true,
    });

    const pagination = buildQueryString({
      beforeThisId,
    });

    return fetch(`${getNotificationsApiUrl()}/stream${pagination}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          this.setState({
            loading: false,
          });
        } else {
          return response.json();
        }
      })
      .then(pick(["notifications", "groups", "unreadCount", "hasMore"]))
      .then((stream) => {
        this.setState(({ stream: currentStream }) => {
          if (beforeThisId) {
            return {
              loading: false,
              stream: {
                ...currentStream,
                hasMore: stream.hasMore,
                unreadCount: stream.unreadCount,
                notifications: currentStream.notifications.concat(stream.notifications),
                groups: {
                  ...currentStream.groups,
                  ...stream.groups,
                },
              },
            };
          }

          return {
            loading: false,
            stream,
          };
        });
      })
      .catch(noop);
  };

  handleRead = (notification: Notification) => {
    if (notification.readAt || notification.completedAt || notification.deletedAt) {
      return Promise.resolve(notification);
    }

    const promise = fetch(`${getNotificationsApiUrl()}/read`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${this.props.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        notification: notification._id,
      }),
    })
      .then(() => notification)
      .catch(noop);

    this.setState(({ stream, ...state }) => {
      return {
        ...state,
        stream: {
          ...stream,
          unreadCount: stream.unreadCount - 1,
          notifications: stream.notifications.map((maybeNotification) => {
            if (notification._id !== maybeNotification._id) {
              return maybeNotification;
            }

            return {
              ...notification,
              readAt: new Date().toISOString(),
            };
          }),
        },
      };
    });

    return promise;
  };

  handleReadAll = () => {
    const promise = fetch(`${getNotificationsApiUrl()}/readAll`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${this.props.token}`,
      },
    }).catch(noop);

    this.setState(({ stream, ...state }) => {
      return {
        ...state,
        stream: {
          ...stream,
          unreadCount: 0,
          notifications: stream.notifications.map((notification) => {
            if (notification.readAt) {
              return notification;
            }

            return {
              ...notification,
              readAt: new Date().toISOString(),
            };
          }),
        },
      };
    });

    return promise;
  };

  readNotifications = ({ readAt, groups, notifications }: { readAt: Date; groups: string[]; notifications: any[] }) => {
    this.setState(({ stream, ...state }) => {
      return {
        ...state,
        stream: {
          ...stream,
          unreadCount: stream.unreadCount - notifications.length - groups.length,
          groups: Object.keys(stream.groups).reduce((streamGroups, group) => {
            if (groups.includes(group)) {
              return update(
                [group, "notifications"],
                (notifications) => notifications.map(set("readAt", readAt)),
                streamGroups,
              );
            }

            return streamGroups;
          }, stream.groups),
          notifications: stream.notifications.map((notification) => {
            if (notifications.includes(notification._id) || groups.includes(notification.group)) {
              return {
                ...notification,
                readAt,
              };
            }

            return notification;
          }),
        },
      };
    });
  };

  receiveNotification = (message: NotificationMessage) => {
    this.setState(({ stream, ...state }) => {
      const { unreadCount, notifications, groups } = stream;

      if (message.group) {
        const hasAnyRead = notifications.some(
          ({ group, readAt }) => group === message.notification.group && readAt !== null,
        );

        const hasGroup = has(message.notification.group, groups);

        return {
          ...state,
          stream: {
            ...stream,
            unreadCount: unreadCount + (hasAnyRead || !hasGroup ? 1 : 0),
            notifications: notifications
              .filter(({ group }) => group !== message.notification.group)
              .concat(message.notification),
            groups: {
              ...groups,
              [message.notification.group]: message.group,
            },
          },
        };
      } else {
        return {
          ...state,
          stream: {
            ...stream,
            unreadCount: unreadCount + 1,
            notifications: notifications.concat(message.notification),
          },
        };
      }
    });
  };

  handleIncomingMessage = ({ message }: { message: { type: string; data: any } }) => {
    const eventTypes = ["com.govlaunch.notifications:read", "com.govlaunch.notifications:push"];

    if (eventTypes.includes(message.type)) {
      if (message.type === "com.govlaunch.notifications:read") {
        this.readNotifications(message.data);
      } else if (message.type === "com.govlaunch.notifications:push") {
        this.receiveNotification(message.data);
      }
    }
  };

  render() {
    const { children } = this.props;
    const {
      loading,
      stream: { unreadCount, notifications, groups, hasMore },
    } = this.state;

    return children({
      unreadCount: unreadCount,
      notifications: sortNotifications(notifications || []),
      groups: groups,
      read: this.handleRead,
      readAll: this.handleReadAll,
      hasMore: hasMore,
      loading,
      loadMore: (beforeThisId) => this.fetchStream(beforeThisId),
    });
  }
}

function sortNotifications(notificationsToBeSorted: Notification[]) {
  const notifications = notificationsToBeSorted.slice();

  notifications.sort((a, b) => {
    const prioritySort = b.priority - a.priority;
    const createdAtSort = new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

    if (a.priority === 0 && b.priority === 0) {
      return createdAtSort;
    } else {
      return prioritySort;
    }
  });

  return notifications;
}

function buildQueryString(query = {}) {
  return queryString.stringify(query, {
    addQueryPrefix: true,
    skipNulls: true,
  });
}
