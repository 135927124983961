import get from "lodash/fp/get";
import React, { ReactElement } from "react";
import useIsMobile from "~/lib/hooks/useIsMobile";
import Auth from "~/components/auth/Auth";
import DesktopNotifications from "~/components/notifications/DesktopNotifications";
import MobileNotifications from "~/components/notifications/MobileNotifications";

export default function UserNotifications(): ReactElement {
  const isMobile = useIsMobile();

  return (
    <Auth>
      {({ user, token }) => {
        if (!user) {
          return null;
        }

        const channels = [user._id, get("government._id", user), get("company._id", user)].filter(Boolean);

        if (isMobile) {
          return <MobileNotifications token={token} channels={channels} />;
        }

        return <DesktopNotifications token={token} channels={channels} />;
      }}
    </Auth>
  );
}
