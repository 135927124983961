import * as palette from "@govlaunch/palette";
import React, { HTMLAttributes } from "react";

export default function Alert({ children }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        display: "grid",
        gridColumnGap: 14,
        gridTemplateColumns: "32px 1fr",
        alignItems: "center",
        padding: 20,
        color: palette.text,
        fontSize: 16,
        lineHeight: 1.33,
        backgroundColor: "#ffdfdf",
        border: "solid 1px #ff7171",
        borderRadius: 3,
      }}
    >
      <ExclamationSign />

      {children}
    </div>
  );
}

function ExclamationSign(props: React.SVGProps<any>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#FF7171"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        transform="translate(1 1)"
      >
        <circle cx={15} cy={15} r={15} />
        <rect width={2} height={10} x={14} y={7} rx={1} />
        <circle cx={15} cy={22} r={1} />
      </g>
    </svg>
  );
}
