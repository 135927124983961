import { useIntlLocale } from "~/lib/withIntl";

export default function useIntlPolicies() {
  const { locale } = useIntlLocale();

  if (locale.startsWith("pt")) {
    return {
      termsAndConditions: "https://www.iubenda.com/termos-e-condicoes/86933505?an=no&height=800",
      privacyPolicy: "https://www.iubenda.com/privacy-policy/86933505?an=no&height=800",
      cookiePolicy: "https://www.iubenda.com/privacy-policy/86933505/cookie-policy?an=no&height=800",
    };
  }

  if (locale.startsWith("de")) {
    return {
      termsAndConditions: "https://www.iubenda.com/terms-and-conditions/8224354?an=no&height=800",
      privacyPolicy: "https://www.iubenda.com/privacy-policy/8224354?an=no&height=800",
      cookiePolicy: "https://www.iubenda.com/privacy-policy/8224354/cookie-policy?an=no&height=800",
    };
  }

  return {
    termsAndConditions: "https://www.iubenda.com/terms-and-conditions/8224354?an=no&height=800",
    privacyPolicy: "https://www.iubenda.com/privacy-policy/8224354?an=no&height=800",
    cookiePolicy: "https://www.iubenda.com/privacy-policy/8224354/cookie-policy?an=no&height=800",
  };
}
