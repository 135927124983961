import { Avatar, Box, BoxProps, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Text } from "@govlaunch/web";
import Link from "next/link";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useToggle } from "react-use";
import Auth from "~/components/auth/Auth";
import Icon, { CogIcon, LogOutIcon, ReferralsIcon, UserIcon } from "~/components/icons/UserIcons";
import Referrals from "~/components/navbar/Referrals";
import { ISelfieQuery } from "~/lib/queries/__generated__/SelfieQuery.generated";
import getGovlaunchBaseUrl from "~/lib/utils/getGovlaunchBaseUrl";

interface IProfileDropdown {
  user: NonNullable<ISelfieQuery["selfie"]>;
}

export default function UserProfileDropdown({ user }: IProfileDropdown): ReactElement | null {
  const [isReferralsOpen, toggleReferralsOpen] = useToggle(false);

  return (
    <>
      {isReferralsOpen && (
        <Referrals
          isOpen={isReferralsOpen}
          onRequestClose={() => toggleReferralsOpen()}
          referralUrl={`${getGovlaunchBaseUrl("portal")}?referral=${user._id}`}
        />
      )}

      <Popover placement="bottom-end">
        <PopoverTrigger>
          <a href="#">
            <Avatar name={user.fullName} src={user.avatar} size="sm" />
          </a>
        </PopoverTrigger>

        <PopoverContent arrowShadowColor="#3F4A5A" css={{ position: "absolute" }}>
          <PopoverArrow />

          <Box bg="gray.900" borderRadius="md" position="relative">
            <Box bg="gray.600" borderTopLeftRadius="md" borderTopRightRadius="md" p={3}>
              <div
                css={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar src={user.avatar} name={user.fullName} size="sm" />

                <Text fontWeight="bold" color="white" ml={2} fontSize="lg" isTruncated={true}>
                  {user.fullName}
                </Text>
              </div>
            </Box>

            <ul
              css={{
                listStyle: "none",
                margin: 0,
                padding: 12,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <Item>
                <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug!}`}>
                  <a
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon>
                      <UserIcon />
                    </Icon>
                    <FormattedMessage defaultMessage="My Profile" id="YzZa8+" />
                  </a>
                </Link>
              </Item>

              {user.__typename === "GovernmentUser" && user.government && (
                <Item>
                  <Link
                    href="/governments/[governmentSlug]/[[...content]]"
                    as={`/governments/${user.government!.slug}`}
                    passHref={true}
                  >
                    <a
                      css={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Icon>
                        <Avatar size="xs" src={user.government.logo} name={user.government.name} />
                      </Icon>

                      <Text fontSize="md" isTruncated={true}>
                        {user.government.name}
                      </Text>
                    </a>
                  </Link>
                </Item>
              )}

              {user.__typename === "VendorUser" && user.company && (
                <Item>
                  <Link href="/vendors/[vendorSlug]" as={`/vendors/${user.company!.slug}`} passHref={true}>
                    <a
                      css={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Icon>
                        <Avatar size="xs" src={user.company.logo} name={user.company.name} />
                      </Icon>

                      <Text fontSize="md" isTruncated={true}>
                        {user.company.name}
                      </Text>
                    </a>
                  </Link>
                </Item>
              )}

              {user.groups.length > 0 && (
                <>
                  <Divider />

                  {user.groups.map((group) => (
                    <Item key={group._id}>
                      <Link passHref={true} href="/groups/[groupSlug]" as={`/groups/${group.slug}`}>
                        <a
                          css={{
                            display: "flex",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          <Icon>
                            <Avatar size="xs" src={group.logo} name={group.name} />
                          </Icon>

                          <Text fontSize="md" isTruncated={true}>
                            {group.name}
                          </Text>
                        </a>
                      </Link>
                    </Item>
                  ))}

                  <Divider />
                </>
              )}

              <Item>
                <Link passHref={true} href={`/@${user.slug}/settings`}>
                  <a
                    css={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon>
                      <CogIcon />
                    </Icon>
                    <FormattedMessage defaultMessage="Settings" id="D3idYv" />
                  </a>
                </Link>
              </Item>

              <Item>
                <a
                  href="#"
                  onClick={() => {
                    toggleReferralsOpen();
                  }}
                  css={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon>
                    <ReferralsIcon />
                  </Icon>
                  <FormattedMessage defaultMessage="Referrals" id="CrJOJJ" />
                </a>
              </Item>

              <Item>
                <Auth>
                  {({ logout }) => (
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        logout();
                      }}
                      css={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Icon>
                        <LogOutIcon />
                      </Icon>
                      <FormattedMessage defaultMessage="Log Out" id="H0JBH6" />
                    </a>
                  )}
                </Auth>
              </Item>
            </ul>
          </Box>
        </PopoverContent>
      </Popover>
    </>
  );
}

function Divider() {
  return <Box height="1px" width="100%" bg="gray.200" />;
}

function Item(props: BoxProps) {
  return (
    <Box
      fontSize="md"
      px={1}
      py={3}
      color="gray.100"
      display="flex"
      alignItems="center"
      {...props}
      fontWeight="semibold"
    />
  );
}
