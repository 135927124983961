import { ITag } from "~/types/types";

export default function getTagFromQuery(tags: Pick<ITag, "name">[], query: string | null): string | null {
  if (tags.length === 0) {
    return null;
  }

  if (tags.length === 1 || !query) {
    return tags[0].name;
  }

  const matchedTag = tags.find(({ name }) => (name || "").toLowerCase().includes(query.toLowerCase()));

  return matchedTag ? matchedTag.name : null || tags[0].name;
}
