import { Anchor, Box, Flex } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import { useSelfie } from "~/components/auth/Auth";
import NavbarSearch from "~/components/navbar/NavbarSearch";
import UserNotifications from "~/components/notifications/UserNotifications";
import useWindowDimensions from "~/lib/hooks/useWindowDimensions";
import { MobileNavbarLogo } from "~/src/components/navbar/icons";
import JoinButtons from "~/src/components/navbar/JoinButtons";
import MobileMenu from "~/src/components/navbar/MobileMenu";

export default function MobileNavbar() {
  const user = useSelfie();
  const { width } = useWindowDimensions();
  const hideSignUpButton = width && width < 480 ? true : false;

  return (
    <Box bg="gray.700" px={5} as="header">
      <Box
        maxWidth="1224px"
        display="flex"
        mx="auto"
        alignItems="center"
        position="relative"
        width="100%"
        height="56px"
        zIndex={305}
      >
        <nav>
          <MobileMenu />
        </nav>
        <header>
          <Link href="/" passHref={true}>
            <Anchor display="inline-flex" ml={4} maxWidth="115px" aria-label="homepage">
              <MobileNavbarLogo />
            </Anchor>
          </Link>
        </header>
        {user ? (
          <Flex flex={1} ml={8} align="center" justify="flex-end">
            <NavbarSearch />

            <Box display="flex" alignItems="center" ml={6}>
              <UserNotifications />
            </Box>
          </Flex>
        ) : (
          <nav>
            <Flex flex={1} align="center" justify="flex-end" minWidth={hideSignUpButton ? "" : "170px"} marginLeft={2}>
              <JoinButtons mr={2} isMobile={hideSignUpButton} />
              <NavbarSearch />
            </Flex>
          </nav>
        )}
      </Box>
    </Box>
  );
}
